/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UnhandledMessageCountDTO } from '@api/mainServiceAPI';
import {
    CheckCodeRequestDTO,
    CheckCodeResponseDTO,
    CreateSessionResponseDTO,
    RegisterSmsSendRequestDTO,
    UserProfileDetailsDTO,
    UserRecoverPasswordDTO,
} from '@api/userServiceAPI';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import { IAuthUser, ICurrentProfile, IUserRegisterDTO, IUserState, UserRole, initialUserState } from './state';

// Slice
export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        login: (state: IUserState, action: PayloadAction<{ login: string; password: string }>) => {},
        logoutLocal: (state: IUserState, action: PayloadAction<{ isEsia?: boolean }>) => {},
        getAuthUser: (state: IUserState, action: PayloadAction<{ newProfileAdded?: boolean }>) => {},
        setAuthUser: (state: IUserState, action: PayloadAction<IAuthUser | null>) => {
            state.authUser = action.payload;
        },
        setCurrentProfile: (state: IUserState, action: PayloadAction<ICurrentProfile | null>) => {
            state.currentProfile = action.payload;
            state.authUser = { ...state.authUser, role: action.payload ? action.payload.profileType : UserRole.End_user };
        },
        setSwitchedToEndUser: (state: IUserState, action: PayloadAction<boolean>) => {
            state.switchedToEndUser = action.payload;
        },
        sendEmail: (state: IUserState, action: PayloadAction<{ email: string }>) => {
            state.resendAfterFirst = initialUserState.resendAfterFirst;
            state.expirationDateEmail = initialUserState.expirationDateEmail;
            state.email = action.payload.email;
        },
        restorePassword: (state: IUserState, action: PayloadAction<{ email: string }>) => {
            state.resendRecoverAfterFirst = initialUserState.resendRecoverAfterFirst;
            state.expirationDateRecoverEmail = initialUserState.expirationDateRecoverEmail;
            state.emailRecover = action.payload.email;
        },
        proceedRegistration: (state: IUserState, action: PayloadAction<CreateSessionResponseDTO>) => {
            state.resendAfterFirst = action.payload.resendAfter;
            state.codeLengthEmail = action.payload.codeLength;
            state.attemptsEmail = action.payload.attempts;
            state.sessionIdFirst = action.payload.sessionId;
            state.expirationDateEmail = action.payload.expirationDate;
        },
        proceedRestorePassword: (state: IUserState, action: PayloadAction<CreateSessionResponseDTO>) => {
            state.resendRecoverAfterFirst = action.payload.resendAfter;
            state.codeRecoverLengthEmail = action.payload.codeLength;
            state.attemptsRecoverEmail = action.payload.attempts;
            state.sessionRecoverId = action.payload.sessionId;
            state.expirationDateRecoverEmail = action.payload.expirationDate;
        },
        initRegisterAction: (state: IUserState, action: PayloadAction<CheckCodeRequestDTO>) => {
            state.attemptsEmail = state.attemptsEmail ? state.attemptsEmail - 1 : undefined;
        },
        restorePasswordConfirmCode: (state: IUserState, action: PayloadAction<CheckCodeRequestDTO>) => {
            state.attemptsRecoverEmail = state.attemptsRecoverEmail ? state.attemptsRecoverEmail - 1 : undefined;
        },
        registrationInitDone: (state: IUserState, action: PayloadAction<CheckCodeResponseDTO>) => {
            state.emailTokenAfter = action.payload.dataOrToken;
        },
        restorePwdInitDone: (state: IUserState, action: PayloadAction<CheckCodeResponseDTO>) => {
            state.emailRecoverToken = action.payload.dataOrToken;
        },
        storeFormData: (state: IUserState, action: PayloadAction<IUserRegisterDTO>) => {
            state.accountFormData = action.payload;
        },
        sendSMS: (state: IUserState, action: PayloadAction<RegisterSmsSendRequestDTO>) => {
            state.resendAfterSecond = initialUserState.resendAfterSecond;
            state.expirationDatePhone = initialUserState.expirationDatePhone;
            state.phoneFormData = action.payload.phone;
        },
        setPhone: (state: IUserState, action: PayloadAction<{ phone: string }>) => {
            state.phoneFormData = action.payload.phone;
        },
        setEmail: (state: IUserState, action: PayloadAction<{ email: string }>) => {
            state.email = action.payload.email;
        },
        smsSent: (state: IUserState, action: PayloadAction<CreateSessionResponseDTO>) => {
            state.resendAfterSecond = action.payload.resendAfter;
            state.codeLengthSMS = action.payload.codeLength;
            state.attemptsSMS = action.payload.attempts;
            state.sessionIdSecond = action.payload.sessionId;
            state.expirationDatePhone = action.payload.expirationDate;
        },
        confirmPhone: (
            state: IUserState,
            action: PayloadAction<CheckCodeRequestDTO & { accountFormData: IUserRegisterDTO; emailTokenAfter: string }>,
        ) => {
            state.attemptsSMS = state.attemptsSMS ? state.attemptsSMS - 1 : undefined;
        },
        registerWithoutPhoneConfirmation: (
            state: IUserState,
            action: PayloadAction<CheckCodeRequestDTO & { accountFormData: IUserRegisterDTO; emailTokenAfter: string }>,
        ) => {
        },
        phoneConfirmed: (state: IUserState, action: PayloadAction<CheckCodeResponseDTO>) => {
            state.phoneTokenAfter = action.payload.dataOrToken;
        },
        registered: (state: IUserState, action: PayloadAction<void>) => {
            state.registeredSuccessfully = true;
        },
        resetData: (state: IUserState, action: PayloadAction<void>) => {
            state.email = initialUserState.email;
            state.registeredSuccessfully = initialUserState.registeredSuccessfully;
            state.emailTokenAfter = initialUserState.emailTokenAfter;
            state.phoneTokenAfter = initialUserState.phoneTokenAfter;
            state.accountFormData = initialUserState.accountFormData;
            state.phoneFormData = initialUserState.phoneFormData;
            state.sessionIdFirst = initialUserState.sessionIdFirst;
            state.sessionIdSecond = initialUserState.sessionIdSecond;
            state.resendAfterFirst = initialUserState.resendAfterFirst;
            state.resendAfterSecond = initialUserState.resendAfterSecond;
            state.expirationDatePhone = initialUserState.expirationDatePhone;
            state.expirationDateEmail = initialUserState.expirationDateEmail;
            state.expirationDateRecoverEmail = initialUserState.expirationDateRecoverEmail;
            state.remoteValidation = initialUserState.remoteValidation;
            state.codeLengthEmail = initialUserState.codeLengthEmail;
            state.codeLengthSMS = initialUserState.codeLengthSMS;
            state.emailRecover = initialUserState.emailRecover;
            state.attemptsEmail = initialUserState.attemptsEmail;
            state.sessionRecoverId = initialUserState.sessionRecoverId;
            state.resendRecoverAfterFirst = initialUserState.resendRecoverAfterFirst;
            state.attemptsRecoverEmail = initialUserState.attemptsRecoverEmail;
            state.codeRecoverLengthEmail = initialUserState.codeRecoverLengthEmail;
            state.emailRecoverToken = initialUserState.emailRecoverToken;
            state.passwordRecoveredSuccessfully = initialUserState.passwordRecoveredSuccessfully;
        },
        resetSessionIdSecond: (state: IUserState, action: PayloadAction<void>) => {
            state.sessionIdSecond = initialUserState.sessionIdSecond;
        },
        remoteValidationError: (state: IUserState, action: PayloadAction<{ field: string; error?: string }>) => {
            const { field, error } = action.payload;
            state.remoteValidation[field] = error;
            state.remoteValidation = { ...state.remoteValidation };
        },
        saveRecoveredPassword: (state: IUserState, action: PayloadAction<UserRecoverPasswordDTO>) => {},
        passwordRecovered: (state: IUserState, action: PayloadAction<void>) => {
            state.passwordRecoveredSuccessfully = true;
        },

        getDocInfoModel: (state: IUserState, action: PayloadAction<string>) => {},
        setDocInfoModel: (state: IUserState, action: PayloadAction<UserProfileDetailsDTO | undefined>) => {
            state.docInfoModel = action.payload;
        },

        switchToEndUser: (state: IUserState, action: PayloadAction<string | null>) => {
            state.currentProfile = null;
            state.authUser = { ...state.authUser, role: UserRole.End_user };
            state.switchEndUserRedirect = action.payload;
        },

        getUnhandledMsgs: (state: IUserState, action: PayloadAction<void>) => {},
        setUnhandledMsgs: (state: IUserState, action: PayloadAction<UnhandledMessageCountDTO>) => {
            state.unhandledMsgs = action.payload.activeMessagesCount || 0;
        },
        registerEsia: (state: IUserState, action: PayloadAction<{ user: IUserRegisterDTO, email: string, phone: string}>) => {},
        getLinkEsia: (state: IUserState, action: PayloadAction<void>) => {},
        setLinkEsia: (state: IUserState, action: PayloadAction<string>) => {
            state.linkEsia = action.payload;
        },
        getUserInfoEsia: (state: IUserState, action: PayloadAction<string>) => {},
        setRegistrationUserEsia: (state: IUserState, action: PayloadAction<boolean>) => {
            state.registrationUserEsia = action.payload;
        },
    },
});

// Actions
export const {
    storeFormData,
    sendSMS,
    login,
    logoutLocal,
    setAuthUser,
    getAuthUser,
    setCurrentProfile,
    sendEmail,
    proceedRegistration,
    registrationInitDone,
    initRegisterAction,
    registerWithoutPhoneConfirmation,
    smsSent,
    remoteValidationError,
    confirmPhone,
    phoneConfirmed,
    registered,
    resetData,
    resetSessionIdSecond,
    restorePassword,
    proceedRestorePassword,
    restorePasswordConfirmCode,
    saveRecoveredPassword,
    restorePwdInitDone,
    passwordRecovered,
    getDocInfoModel,
    setDocInfoModel,
    switchToEndUser,
    setSwitchedToEndUser,
    getUnhandledMsgs,
    setUnhandledMsgs,
    setPhone,
    setEmail,
    registerEsia,
    getLinkEsia,
    setLinkEsia,
    getUserInfoEsia,
    setRegistrationUserEsia
} = userSlice.actions;

// Reducer
export const userReducer = userSlice.reducer;

// Selectors
export const selectAuthUser = (state: RootState) => state.userReducer.authUser;
export const selectCurrentProfile = (state: RootState) => state.userReducer.currentProfile;
export const selectEmail = (state: RootState) => state.userReducer.email;
export const selectEmailRecover = (state: RootState) => state.userReducer.emailRecover;
export const selectEmailTokenAfter = (state: RootState) => state.userReducer.emailTokenAfter;
export const selectEmailRecoverToken = (state: RootState) => state.userReducer.emailRecoverToken;
export const selectAccountFormData = (state: RootState) => state.userReducer.accountFormData;
export const selectPhoneTokenAfter = (state: RootState) => state.userReducer.phoneTokenAfter;
export const selectRegisteredSuccessfully = (state: RootState) => state.userReducer.registeredSuccessfully;
export const selectPhoneNumber = (state: RootState) => state.userReducer.phoneFormData;
export const selectRemoteValidationMessages = (state: RootState) => state.userReducer.remoteValidation;
export const selectSessionIdFirst = (state: RootState) => state.userReducer.sessionIdFirst;
export const selectSessionRecoverId = (state: RootState) => state.userReducer.sessionRecoverId;
export const selectSessionIdSecond = (state: RootState) => state.userReducer.sessionIdSecond;
export const selectResendAfterFirst = (state: RootState) => state.userReducer.resendAfterFirst;
export const selectResendRecoverAfterFirst = (state: RootState) => state.userReducer.resendRecoverAfterFirst;
export const selectResendAfterSecond = (state: RootState) => state.userReducer.resendAfterSecond;
export const selectExpirationDatePhone = (state: RootState) => state.userReducer.expirationDatePhone;
export const selectExpirationDateEmail = (state: RootState) => state.userReducer.expirationDateEmail;
export const selectExpirationDateRecoverEmail = (state: RootState) => state.userReducer.expirationDateRecoverEmail;
export const selectAttemptsEmail = (state: RootState) => state.userReducer.attemptsEmail;
export const selectAttemptsRecoverEmail = (state: RootState) => state.userReducer.attemptsRecoverEmail;
export const selectAttemptsSMS = (state: RootState) => state.userReducer.attemptsSMS;
export const selectCodeLengthEmail = (state: RootState) => state.userReducer.codeLengthEmail;
export const selectCodeRecoverLengthEmail = (state: RootState) => state.userReducer.codeRecoverLengthEmail;
export const selectCodeLengthSMS = (state: RootState) => state.userReducer.codeLengthSMS;
export const selectPasswordRecoveredSuccessfully = (state: RootState) => state.userReducer.passwordRecoveredSuccessfully;
export const selectDocInfoModel = (state: RootState) => state.userReducer.docInfoModel;
export const selectSwitchEndUserRedirect = (state: RootState) => state.userReducer.switchEndUserRedirect;
export const selectUnhandledMsgs = (state: RootState) => state.userReducer.unhandledMsgs;
export const selectSwitchedToEndUser = (state: RootState) => state.userReducer.switchedToEndUser;
export const selectRegistrationUserEsia = (state: RootState) => state.userReducer.registrationUserEsia;
export const selectLinkEsia = (state: RootState) => state.userReducer.linkEsia;


