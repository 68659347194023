import { UserDTO } from '@api/userServiceAPI';
import { AlertIconOutlined, ProcedureEditIcon } from '@icons';
import { Button, Modal } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';
import styles from './AccountInfo.module.scss';

interface AccountInfoProps {
    selectedEmployee: UserDTO | undefined;
    blocked: boolean | undefined;
    onEditAccount?: () => void;
    onUnBlockEmployeeAccount?: () => void;
    onBlockEmployeeAccount?: () => void;
    previewSelfMode?: boolean;
}

export const AccountInfo = ({
    selectedEmployee,
    blocked,
    onEditAccount,
    onUnBlockEmployeeAccount,
    onBlockEmployeeAccount,
    previewSelfMode,
}: AccountInfoProps) => {
    const [blockUserModalOpen, setBlockUserModalOpen] = useState(false);

    const getUserRole = () => {
        if (selectedEmployee?.userProfileDTOList) {
            return selectedEmployee?.userProfileDTOList[0] ? selectedEmployee?.userProfileDTOList[0].profileType : 'End_user';
        }
        return 'End_user';
    };

    const getFullName = () => {
        return selectedEmployee?.lastName + ' ' + selectedEmployee?.firstName + ' ' + (selectedEmployee?.middleName || '');
    };

    const onConfirmBlockUser = () => {
        onBlockEmployeeAccount?.();
        setBlockUserModalOpen(false);
    };

    return (
        <div>
            {!previewSelfMode && (
                <div className={styles.accountTitle}>
                    <h4>{t('adminEmployeePage.account')}</h4>
                    {onEditAccount && (
                        <a onClick={() => onEditAccount()}>
                            <ProcedureEditIcon />
                        </a>
                    )}
                </div>
            )}
            <div className={styles.infoContainer}>
                {blocked && (
                    <div className={styles.infoBlocked}>
                        <AlertIconOutlined />
                        <p className={styles.infoValue}>{t('adminEmployeePage.userBlocked')}</p>
                    </div>
                )}
                <div className={styles.infoItem}>
                    <p className={styles.infoTitle}>{t('adminEmployeePage.mobile')}</p>
                    <p className={styles.infoValue}> {selectedEmployee?.mobile ? '+' + selectedEmployee?.mobile : '-'}</p>
                </div>
                <div className={styles.infoItem}>
                    <p className={styles.infoTitle}>{t('adminEmployeePage.email')}</p>
                    <p className={styles.infoValue}>{selectedEmployee?.email}</p>
                </div>
                {/*<div className={styles.infoItem}>
                    <p className={styles.infoTitle}>{t('adminEmployeePage.snils')}</p>
                    <p className={styles.infoValue}>{selectedEmployee?.userProfileDTOList?.at(0)!.userProfileDetailsDTO && selectedEmployee?.userProfileDTOList?.at(0)!.userProfileDetailsDTO!.snils ?
                        selectedEmployee?.userProfileDTOList?.at(0)!.userProfileDetailsDTO!.snils : t("patient_medcard.no_snils")}</p>
                </div>*/}
                <div className={styles.infoItem}>
                    <p className={styles.infoTitle}>{t('adminEmployeePage.role')}</p>
                    <p className={styles.infoValue}>{t(`adminMainPage.roles.${getUserRole()}`)}</p>
                </div>
                {blocked ? (
                    <>
                        {onUnBlockEmployeeAccount && (
                            <Button className={styles.unblockButton} onClick={() => onUnBlockEmployeeAccount()}>
                                {t('adminEmployeePage.unblockUser')}
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        {onBlockEmployeeAccount && (
                            <a className={styles.blockButton} onClick={() => setBlockUserModalOpen(true)}>
                                {t("account_info.lock_account")}{' '}
                            </a>
                        )}
                    </>
                )}
            </div>
            {blockUserModalOpen && (
                <Modal
                    width={592}
                    title={t('adminEmployeePage.modals.blockUser.title')}
                    okText={t('adminEmployeePage.modals.blockUser.ok')}
                    cancelText={t('adminEmployeePage.modals.blockUser.cancel')}
                    open={blockUserModalOpen}
                    onOk={() => onConfirmBlockUser()}
                    onCancel={() => setBlockUserModalOpen(false)}
                    forceRender
                >
                    <p>{`${t('adminEmployeePage.modals.blockUser.action')} ${getFullName()}.`}</p>
                    <br />
                    <p>{t('adminEmployeePage.modals.blockUser.effect')}</p>
                </Modal>
            )}
        </div>
    );
};
