import {
    AppointmentParticipantDetailsDTOCourseType,
    ConsultationAppointmentDetailsDTO,
    ConsultationDetailsDTO,
    ConsultationResultDTOResult,
    CreateCarePlanDTO,
    UpdateCarePlanDTO,
    createCarePlan,
    createConsultationResult,
    getConsultationDetails,
    updateAppointment,
    updateCarePlan,
    updateConsultationResult,
} from '@api/mainServiceAPI';
import { ProcedureTypeEnum } from '@enums';
import { ConsultationUserIcon, LockIcon, ScreenDisabledIcon, StepDisabledIcon, StepDoneIcon, StepInfoIcon, StepSignedIcon } from '@icons';
import { ScreenSettingsModal } from '@modals';
import { selectAuthUser } from '@sliceUser';
import { WsProcedureTopicType } from '@utils/websocket.topics';
import { Button, message } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ILobbyParticipant } from 'src/contexts/kurento.context';
import { WebsocketContext } from 'src/contexts/ws.context';
import { CourseDevice } from 'src/enums/course-device.enum';
import { SessionParticipant } from 'src/models/session-participant';
import { COURSE_MAX_DAYS, CourseProceduresList, getOcclusionByDays } from '../../../../../COURSES_CONFIG';
import {
    DiagnosticBinocular,
    DiagnosticVisualAcuity,
    IDiagnosticBinocularResult,
    IDiagnosticVisualAcuityResult,
} from '../../../../../components/diagnostics';
import styles from './ConsultationSession.module.scss';
import { ComplaintsStep, IComplaintForm, complaintsFormArray } from './steps/ComplaintsStep/ComplaintsStep';
import { CourseStep, ICourseForm, IOcclusionDay, IProcedureFormItem, fixProcedureSettingsTypes } from './steps/CourseStep/CourseStep';
import { DiagnosisStep, IDiagnosisForm } from './steps/DiagnosisStep/DiagnosisStep';
import { PatientInfoStep } from './steps/PatientInfoStep/PatientInfoStep';
import {
    IRecommendationsForm,
    RecommendationsStep,
    additionalExaminationsFormArray,
    dynamicObservationFormArray,
    dynamicsRecommendationsFormArray,
    lifestyleRecommendationsFormArray,
    recommendationsForConsultingAdditionalSpecialistsFormArray,
} from './steps/RecommendationsStep/RecommendationsStep';
import { ReportStep } from './steps/ReportStep/ReportStep';
import { IStatusOculorumForm, StatusOculorumStep } from './steps/StatusOculorumStep/StatusOculorumStep';

interface IStep {
    number: number;
    label: string;
    confirmed?: boolean;
    disabled?: boolean;
}

interface IConsultationSessionProps {
    encounterId?: string;
    courseType?: AppointmentParticipantDetailsDTOCourseType;
    participant?: SessionParticipant;
    activeScreenSharingParticipant?: SessionParticipant;
    onRequestScreenSharing?: (state: boolean, participant: SessionParticipant) => void;
    participantInLobby?: ILobbyParticipant;
    viewOnlyData?: ConsultationResultDTOResult;
    isCalibrationReady?: boolean;
    participantDiagonal?: string;

    onApproveFromLobby?: (fhirId: string) => void;
    diagnosticChange?: (start: boolean, type?: ProcedureTypeEnum) => void;
    onToggleCalibration?: (state: boolean) => void;
    onToggleCalibrationReady?: (ready: boolean) => void;
    releaseParticipant?: () => void;
    closeSession?: () => void;
}

export const ConsultationSession = ({
    encounterId,
    courseType,
    participant,
    activeScreenSharingParticipant,
    onRequestScreenSharing,
    participantInLobby,
    viewOnlyData,
    isCalibrationReady,
    participantDiagonal,

    onApproveFromLobby,
    diagnosticChange,
    onToggleCalibration,
    onToggleCalibrationReady,
    releaseParticipant,
    closeSession,
}: IConsultationSessionProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { send } = useContext(WebsocketContext);

    const authUser = useSelector(selectAuthUser);

    const [steps, setSteps] = useState<IStep[]>([
        { number: 0, label: 'consultationSession.patientInfoStep.title', confirmed: false, disabled: false },
        { number: 1, label: 'consultationSession.complaintsStep.title', confirmed: false, disabled: false },
        { number: 2, label: 'consultationSession.statusOculorumStep.title', confirmed: false, disabled: false },
        { number: 3, label: 'consultationSession.diagnosisStep.title', confirmed: false, disabled: true },
        { number: 4, label: 'consultationSession.recommendationsStep.title', confirmed: false, disabled: true },
        { number: 5, label: 'consultationSession.courseStep.title', confirmed: false, disabled: true },
        { number: 6, label: 'consultationSession.reportStep.title', confirmed: false, disabled: true },

        { number: 100, label: '' },
        { number: 200, label: '' },
    ]);
    const [availableSteps, setAvailableSteps] = useState<IStep[]>([]);

    const [resultDocumentSigned, setResultDocumentSigned] = useState(false);
    const [patientWasHere, setPatientWasHere] = useState(false); // just for check
    const [currentStep, setCurrentStep] = useState<number>(-1);
    const [conclusionFileNameLabel, setConclusionFileNameLabel] = useState<string>('');

    const [appointmentId, setAppointmentId] = useState<string>();
    const [observationId, setObservationId] = useState<string>();
    const [courseFhirId, setCourseFhirId] = useState<string>();

    const [patientInfoStepValue, setPatientInfoStepValue] = useState<ConsultationAppointmentDetailsDTO>();
    const [complaintsFormValue, setComplaintsFormValue] = useState<IComplaintForm>({ complaints: complaintsFormArray });
    const [statusOculorumFormValue, setStatusOculorumFormValue] = useState<IStatusOculorumForm>({
        diagnosticVisualAcuityMode: 'auto',
        diagnosticBinocularMode: 'auto',
        ownGlassesDataDate: '',
        angleSinoptoforDataDate: '',
        otherValuesDataDate: '',
    });
    const [diagnosisFormValue, setDiagnosisFormValue] = useState<IDiagnosisForm>({
        ou: { items: [{ diagnosis: undefined, props: [] }] },
        od: { items: [{ diagnosis: undefined, props: [] }] },
        os: { items: [{ diagnosis: undefined, props: [] }] },
    });
    const [recommendationsFormValue, setRecommendationsFormValue] = useState<IRecommendationsForm>({
        dynamicsRecommendations: dynamicsRecommendationsFormArray,
        lifestyleRecommendations: lifestyleRecommendationsFormArray,
        dynamicObservation: dynamicObservationFormArray,
        additionalExaminations: additionalExaminationsFormArray,
        recommendationsForConsultingAdditionalSpecialists: recommendationsForConsultingAdditionalSpecialistsFormArray,
    });
    const [courseFormValue, setCourseFormValue] = useState<ICourseForm>({
        durationInDays: COURSE_MAX_DAYS,
        occlusionSettings: Array.from({ length: COURSE_MAX_DAYS }, (_, i) => ({ day: i + 1 })),
    });
    const [showEditPatientDiagonalModal, setShowEditPatientDiagonalModal] = useState(false);
    const [patientDiagonal, setPatientDiagonal] = useState<string>('');

    const screenSharingWrapperRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const videoElement = activeScreenSharingParticipant?.kurentoParticipant?.screenSharingVideo;

        if (screenSharingWrapperRef.current && videoElement) {
            screenSharingWrapperRef.current.appendChild(videoElement);
        }

        return () => {
            if (screenSharingWrapperRef.current && videoElement) {
                screenSharingWrapperRef.current.removeChild(videoElement);
            }
        };
    }, [activeScreenSharingParticipant]);

    useEffect(() => {
        if (encounterId) {
            getConsultationDetails(encounterId!).then((res) => {
                setInitialFormValues(res.data);
                setAppointmentId(res.data.appointmentId);

                if (res.data.baseObservationId) {
                    setObservationId(res.data.baseObservationId);
                } else {
                    createConsultationResult({ encounterId, result: {} }).then((res) => {
                        setObservationId(res.data);
                    });
                }

                let practFullName = `${authUser?.lastName}-${authUser?.firstName?.[0]}`;
                if (authUser?.middleName) {
                    practFullName += `-${authUser?.middleName?.[0]}`;
                }

                const typeLabel = t('enums.appointmentTypes.' + res.data.consultationInitialInformation?.courseType);

                setConclusionFileNameLabel(`${dayjs().format('DD-MM-YYYY')}-${typeLabel.replaceAll(' ', '-')}-${practFullName}.pdf`);
            });
        }
    }, [encounterId]);

    useEffect(() => {
        if (!!participant?.kurentoParticipant) {
            setPatientWasHere(true);
        }
    }, [participant?.kurentoParticipant]);

    useEffect(() => {
        if (viewOnlyData) {
            try {
                setInitialFormValues({
                    consultationResultDTO: { result: viewOnlyData },
                    assignedCarePlan: viewOnlyData.courseStep,
                    encounterStatus: 'finished',
                });
            } catch (error) {
                console.error(error);
                message.error(t("consultationSession.error_form_message"));
            }
        }
    }, [viewOnlyData]);

    useEffect(() => {
        if (courseType) {
            let targetSteps = [...steps].filter((x) => x.number < 100);

            if (viewOnlyData) {
                // without Report and Info
                targetSteps = targetSteps.filter((x) => x.number > 0 && x.number < 6);
            }

            switch (courseType) {
                case 'initial_consultation':
                    // we have all steps
                    break;
                case 'repeated_consultation':
                    // we have all steps
                    break;
                case 'final_consultation':
                    targetSteps = targetSteps.filter((x) => !(x.number === 0 || x.number === 1 || x.number === 3));
                    break;
                default:
                    break;
            }

            setAvailableSteps(targetSteps);

            if (currentStep === -1) {
                setCurrentStep(targetSteps[0].number);
            }
        }
    }, [courseType, viewOnlyData, steps]);

    useEffect(() => {
        if (participantDiagonal) {
            setPatientDiagonal(participantDiagonal);
        }
    }, [participantDiagonal]);

    const setInitialFormValues = (serverResponse: ConsultationDetailsDTO) => {
        if ((serverResponse as any).encounterStatus === 'finished') {
            setResultDocumentSigned(true);
        }

        const updatedSteps = [...steps];

        setPatientInfoStepValue(serverResponse.consultationInitialInformation);

        const consultationResult = serverResponse.consultationResultDTO?.result;
        if (consultationResult && Object.keys(consultationResult).length) {
            if (consultationResult.complaintsStep) {
                const complaintsArray = consultationResult.complaintsStep.complaints || [];

                setComplaintsFormValue({
                    ...consultationResult.complaintsStep,
                    complaints: complaintsFormArray.map((x) => ({
                        ...x,
                        selected: complaintsArray.find((y: any) => y.value === x.value)?.selected === 'true',
                    })),
                });

                updatedSteps.find((x) => x.number === 1)!.confirmed = true;

                updatedSteps.find((x) => x.number === 1)!.disabled = true;
                updatedSteps.find((x) => x.number === 2)!.disabled = false;
            }

            if (consultationResult.statusOculorumStep) {
                setStatusOculorumFormValue({
                    ...consultationResult.statusOculorumStep,
                    ownGlassesDataDate: dayjs(consultationResult.statusOculorumStep.ownGlassesDataDate),
                    angleSinoptoforDataDate: dayjs(consultationResult.statusOculorumStep.angleSinoptoforDataDate),
                    otherValuesDataDate: dayjs(consultationResult.statusOculorumStep.otherValuesDataDate),
                });

                updatedSteps.find((x) => x.number === 2)!.confirmed = true;

                updatedSteps.find((x) => x.number === 1)!.disabled = false;
                updatedSteps.find((x) => x.number === 2)!.disabled = false;
                updatedSteps.find((x) => x.number === 3)!.disabled = false;
            }

            if (consultationResult.diagnosisStep) {
                setDiagnosisFormValue({
                    ...consultationResult.diagnosisStep,
                });

                updatedSteps.find((x) => x.number === 3)!.confirmed = true;

                updatedSteps.find((x) => x.number === 2)!.disabled = false;
                updatedSteps.find((x) => x.number === 3)!.disabled = false;
                updatedSteps.find((x) => x.number === 4)!.disabled = false;
            }

            if (consultationResult.recommendationsStep) {
                const serverValue = consultationResult.recommendationsStep as any;

                setRecommendationsFormValue((prev) => ({
                    dynamicsRecommendations:
                        serverValue.dynamicsRecommendations?.map((x: any) => ({ ...x, selected: x.selected === 'true' })) ||
                        prev.dynamicsRecommendations,
                    lifestyleRecommendations:
                        serverValue.lifestyleRecommendations?.map((x: any) => ({ ...x, selected: x.selected === 'true' })) ||
                        prev.lifestyleRecommendations,
                    dynamicObservation:
                        serverValue.dynamicObservation?.map((x: any) => ({ ...x, selected: x.selected === 'true' })) || prev.dynamicObservation,
                    additionalExaminations:
                        serverValue.additionalExaminations?.map((x: any) => ({ ...x, selected: x.selected === 'true' })) ||
                        prev.additionalExaminations,
                    recommendationsForConsultingAdditionalSpecialists:
                        serverValue.recommendationsForConsultingAdditionalSpecialists?.map((x: any) => ({
                            ...x,
                            selected: x.selected === 'true',
                        })) || prev.recommendationsForConsultingAdditionalSpecialists,

                    dateRepeatedConsultation: serverValue.dateRepeatedConsultation ? dayjs(serverValue.dateRepeatedConsultation) : undefined,
                    datePlanedTherapy: serverValue.datePlanedTherapy ? dayjs(serverValue.datePlanedTherapy) : undefined,
                    datePlanedOperation: serverValue.datePlanedOperation ? dayjs(serverValue.datePlanedOperation) : undefined,
                    datePlanedObservation: serverValue.datePlanedObservation ? dayjs(serverValue.datePlanedObservation) : undefined,
                    datePlanedRepeatedAndTherapy: serverValue.datePlanedRepeatedAndTherapy
                        ? dayjs(serverValue.datePlanedRepeatedAndTherapy)
                        : undefined,
                }));

                updatedSteps.find((x) => x.number === 4)!.confirmed = true;

                updatedSteps.find((x) => x.number === 3)!.disabled = false;
                updatedSteps.find((x) => x.number === 4)!.disabled = false;
                updatedSteps.find((x) => x.number === 5)!.disabled = false;
            }
        }

        if (consultationResult?.courseNotRecommended === 'true') {
            setCourseFormValue({
                courseNotRecommended: true,
            });

            updatedSteps.find((x) => x.number === 5)!.confirmed = true;

            updatedSteps.find((x) => x.number === 4)!.disabled = false;
            updatedSteps.find((x) => x.number === 5)!.disabled = false;
            updatedSteps.find((x) => x.number === 6)!.disabled = false;
        } else {
            const courseValue = serverResponse.assignedCarePlan;
            if (courseValue && Object.keys(courseValue).length > 1) {
                const {
                    planDefinitionID,
                    durationInDays,
                    occlusionSettings,
                    procedureSettings,
                    needGlasses,
                    needGlassesWithoutDyo,
                    glassesWithoutDyoSize,
                    deviceDefinitionIDs,
                    fhirId,
                    targetEye,
                    occlusionType,
                } = courseValue;

                setCourseFormValue({
                    planDefinitionID,
                    durationInDays: durationInDays || COURSE_MAX_DAYS,
                    needGlasses,
                    targetEye,
                    procedures: CourseProceduresList[planDefinitionID!]?.map((p) => {
                        const exist = procedureSettings?.find((x: IProcedureFormItem) => x.procedure === p);
                        if (exist) {
                            return {
                                ...exist,
                                selected: exist.selected === 'true',
                                occlusionEye: exist.od === '{}' ? 'os' : 'od',

                                ou: exist.od === '{}' ? fixProcedureSettingsTypes(exist.os) || {} : fixProcedureSettingsTypes(exist.od) || {},
                                od: exist.od === '{}' ? {} : fixProcedureSettingsTypes(exist.od) || {},
                                os: exist.os === '{}' ? {} : fixProcedureSettingsTypes(exist.os) || {},
                            };
                        } else {
                            return { selected: false, procedure: p };
                        }
                    }),
                    occlusionType: occlusionType as any,
                    occlusionSettings: (occlusionSettings as IOcclusionDay[])?.length
                        ? (occlusionSettings as IOcclusionDay[])
                        : Array.from({ length: COURSE_MAX_DAYS }, (_, i) => ({ day: i + 1 })),
                    needGlassesWithoutDyo,
                    glassesWithoutDyoSize,
                    deviceDefinitionIDs: deviceDefinitionIDs as CourseDevice[],
                });

                setCourseFhirId(fhirId);

                updatedSteps.find((x) => x.number === 5)!.confirmed = true;

                updatedSteps.find((x) => x.number === 4)!.disabled = false;
                updatedSteps.find((x) => x.number === 5)!.disabled = false;
                updatedSteps.find((x) => x.number === 6)!.disabled = false;
            }
        }

        setSteps(updatedSteps);
    };

    const changeStep = (newStep: number) => {
        setSteps((prev) => {
            const updatedSteps = [...prev];
            updatedSteps.find((x) => x.number === newStep)!.disabled = false;
            return updatedSteps;
        });
        setCurrentStep(newStep);
    };

    const goToNextStep = () => {
        const nextStepNumber = availableSteps.find((x) => x.number > currentStep)?.number;
        changeStep(nextStepNumber || availableSteps[0].number);
    };

    const resetStepConfirmation = (targetStep: number) => {
        setSteps((prev) => {
            const updatedSteps = [...prev];

            const step = updatedSteps.find((x) => x.number === targetStep)!;
            step.confirmed = false;

            updatedSteps
                .filter((x) => x.number > step.number)
                .forEach((x) => {
                    x.disabled = true;
                    x.confirmed = false;
                });

            return updatedSteps;
        });
    };

    const diagnosticVisualAcuityFinished = (result?: IDiagnosticVisualAcuityResult) => {
        setStatusOculorumFormValue((prev) => ({ ...prev, diagnosticVisualAcuity: result }));
        changeStep(2); // to statusOculorum
    };

    const diagnosticBinocularFinished = (result?: IDiagnosticBinocularResult) => {
        setStatusOculorumFormValue((prev) => ({ ...prev, diagnosticBinocular: result }));
        changeStep(2); // to statusOculorum
    };

    // ======================== SUBMIT STEPS ========================
    const submitComplaintsStep = (formValue: IComplaintForm) => {
        const result: IComplaintForm = formValue;
        result.complaints = result.complaints?.filter((x) => x.selected);

        if (!result.complaints?.length) {
            (result.complaints as any) = null;
        }

        updateConsultationResult(observationId!, { encounterId, result: { complaintsStep: result } }).then((res) => {
            goToNextStep();

            setSteps((prev) => {
                const updatedSteps = [...prev];
                updatedSteps.find((x) => x.number === 1)!.confirmed = true;
                return updatedSteps;
            });
        });
    };

    const submitStatusOculorumStep = (formValue: IStatusOculorumForm) => {
        const result: any = formValue;

        result.diagnosticBinocular = JSON.stringify(result.diagnosticBinocular!) === '{}' ? undefined : result.diagnosticBinocular;
        result.diagnosticVisualAcuity = JSON.stringify(result.diagnosticVisualAcuity!) === '{}' ? undefined : result.diagnosticVisualAcuity;
        result.ownGlassesDataDate = result.ownGlassesDataDate ? result.ownGlassesDataDate?.format('YYYY-MM-DD') : null;
        result.angleSinoptoforDataDate = result.angleSinoptoforDataDate ? result.angleSinoptoforDataDate?.format('YYYY-MM-DD') : null;
        result.otherValuesDataDate = result.otherValuesDataDate ? result.otherValuesDataDate?.format('YYYY-MM-DD') : null;

        updateConsultationResult(observationId!, { encounterId, result: { statusOculorumStep: result } }).then((res) => {
            goToNextStep();

            setSteps((prev) => {
                const updatedSteps = [...prev];
                updatedSteps.find((x) => x.number === 2)!.confirmed = true;
                return updatedSteps;
            });
        });
    };

    const submitDiagnosisStep = (formValue: IDiagnosisForm) => {
        updateConsultationResult(observationId!, { encounterId, result: { diagnosisStep: formValue } }).then((res) => {
            goToNextStep();

            setSteps((prev) => {
                const updatedSteps = [...prev];
                updatedSteps.find((x) => x.number === 3)!.confirmed = true;
                return updatedSteps;
            });
        });
    };

    const submitRecommendationsStep = (formValue: IRecommendationsForm) => {
        const result: any = formValue;

        result.dateRepeatedConsultation = result.dateRepeatedConsultation ? result.dateRepeatedConsultation?.format('YYYY-MM-DD') : null;
        result.datePlanedTherapy = result.datePlanedTherapy ? result.datePlanedTherapy?.format('YYYY-MM-DD') : null;
        result.datePlanedOperation = result.datePlanedOperation ? result.datePlanedOperation?.format('YYYY-MM-DD') : null;
        result.datePlanedObservation = result.datePlanedObservation ? result.datePlanedObservation?.format('YYYY-MM-DD') : null;
        result.datePlanedRepeatedAndTherapy = result.datePlanedRepeatedAndTherapy ? result.datePlanedRepeatedAndTherapy?.format('YYYY-MM-DD') : null;

        updateConsultationResult(observationId!, { encounterId, result: { recommendationsStep: result } }).then((res) => {
            goToNextStep();

            setSteps((prev) => {
                const updatedSteps = [...prev];
                updatedSteps.find((x) => x.number === 4)!.confirmed = true;
                return updatedSteps;
            });
        });
    };

    const submitCourseStep = (formValue: ICourseForm) => {
        const successCallback = () => {
            goToNextStep();

            setSteps((prev) => {
                const updatedSteps = [...prev];
                updatedSteps.find((x) => x.number === 5)!.confirmed = true;
                return updatedSteps;
            });
        };

        if (formValue.courseNotRecommended) {
            updateConsultationResult(observationId!, { encounterId, result: { courseNotRecommended: true } }).then((res) => {
                successCallback();
            });

            if (courseFhirId) {
                updateCarePlan(courseFhirId, { notRecommended: true }).then((res) => {
                    successCallback();
                });
            }

            return;
        }

        if (formValue) {
            const {
                planDefinitionID,
                durationInDays,
                needGlasses,
                occlusionType,
                procedures,
                targetEye,
                glassesWithoutDyoSize,
                needGlassesWithoutDyo,
                occlusionSettings,
                deviceDefinitionIDs,
                extended,
            } = formValue;

            const mappedOcclusionDays = occlusionType === 'manually' ? occlusionSettings : getOcclusionByDays(durationInDays || 0, occlusionType);

            const baseBody = {
                needGlassesWithoutDyo,
                glassesWithoutDyoSize,
                targetEye,
                needGlasses,
                durationInDays,
                occlusionType: occlusionType || 'without',
                planDefinitionID,
                deviceDefinitionIDs,
                extended,

                occlusionSettings: mappedOcclusionDays,
                procedureSettings: procedures
                    ?.filter((x) => x.selected)
                    .map((x) => {
                        // todo backend wants null instead of empty object
                        x.ou = !x.ou || !Object.keys(x.ou).length ? (null as any) : x.ou;
                        x.od = !x.od || !Object.keys(x.od).length ? (null as any) : x.od;
                        x.os = !x.os || !Object.keys(x.os).length ? (null as any) : x.os;

                        if (occlusionType === 'without') {
                            switch (targetEye) {
                                case 'ou':
                                    delete x.od;
                                    delete x.os;
                                    break;
                                case 'od':
                                    delete x.os;
                                    delete x.ou;
                                    break;
                                case 'os':
                                    delete x.od;
                                    delete x.ou;
                                    break;
                            }
                        } else {
                            delete x.ou;
                        }

                        switch (x.occlusionEye) {
                            case 'od':
                                x.od = x.os || x.ou;
                                delete x.os;
                                delete x.ou;
                                break;
                            case 'os':
                                x.os = x.od || x.ou;
                                delete x.od;
                                delete x.ou;
                                break;
                            default:
                                break;
                        }

                        delete x.occlusionEye;

                        return x;
                    }),
            };

            if (!!courseFhirId) {
                const bodyUpdate: UpdateCarePlanDTO = {
                    ...baseBody,
                    notRecommended: false,
                };

                updateCarePlan(courseFhirId, bodyUpdate).then((res) => {
                    successCallback();
                });
            } else {
                const bodyCreate: CreateCarePlanDTO = {
                    ...baseBody,
                    encounterId: encounterId!,
                    patientId: participant?.fhirId,
                };

                createCarePlan(bodyCreate).then((res) => {
                    if (res?.data?.fhirId) {
                        setCourseFhirId(res.data.fhirId);
                    }
                    successCallback();
                });
            }

            updateConsultationResult(observationId!, { encounterId, result: { courseNotRecommended: false, courseStep: baseBody } }).then((res) => {
                successCallback();
            });
        }
    };

    const submitReportStep = (justCloseRoom: boolean) => {
        if (justCloseRoom) {
            closeSession?.();
            navigate('/');
        } else {
            setResultDocumentSigned(true);

            updateAppointment(appointmentId!, { status: 'fulfilled' }).then(() => {
                send('/msg/gateway-ws/message', '/procedure/' + participant?.fhirId, {
                    type: WsProcedureTopicType.conclusionSigned,
                });

                message.info(t("consultationSession.appointment_finished_message"));
                participant?.kurentoParticipant?.leaveRoom(true);
                delete participant?.kurentoParticipant;
            });
        }
    };

    const renderCurrentStepContent = () => {
        switch (currentStep) {
            case 0:
                return <PatientInfoStep data={patientInfoStepValue} />;
            case 1:
                return (
                    <ComplaintsStep
                        onSubmit={submitComplaintsStep}
                        onFormChange={setComplaintsFormValue}
                        initialValues={complaintsFormValue}
                        disabled={resultDocumentSigned}
                    />
                );
            case 2:
                return (
                    <StatusOculorumStep
                        onSubmit={submitStatusOculorumStep}
                        onFormChange={setStatusOculorumFormValue}
                        initialValues={statusOculorumFormValue}
                        disabled={resultDocumentSigned}
                    />
                );
            case 3:
                return (
                    <DiagnosisStep
                        onSubmit={submitDiagnosisStep}
                        onFormChange={(value) => {
                            setDiagnosisFormValue(value);
                            resetStepConfirmation(3);
                        }}
                        initialValues={diagnosisFormValue}
                        disabled={resultDocumentSigned}
                    />
                );
            case 4:
                return (
                    <RecommendationsStep
                        onSubmit={submitRecommendationsStep}
                        onFormChange={(value) => {
                            setRecommendationsFormValue(value);
                            resetStepConfirmation(4);
                        }}
                        courseType={courseType}
                        initialValues={recommendationsFormValue}
                        disabled={resultDocumentSigned}
                    />
                );
            case 5:
                return (
                    <CourseStep
                        onSubmit={submitCourseStep}
                        onFormChange={(value) => {
                            setCourseFormValue(value);
                            resetStepConfirmation(5);
                        }}
                        initialValues={courseFormValue}
                        disabled={resultDocumentSigned}
                    />
                );
            case 6:
                return (
                    <ReportStep
                        encounterId={encounterId}
                        onSubmit={submitReportStep}
                        disabled={resultDocumentSigned}
                        patientWasHere={patientWasHere || participant?.encounterStatus !== 'planned'}
                        conclusionFileNameLabel={conclusionFileNameLabel}
                    />
                );

            case 100:
                return <DiagnosticVisualAcuity onSaveResults={diagnosticVisualAcuityFinished} diagnosticChange={diagnosticChange} />;
            case 200:
                return (
                    <DiagnosticBinocular
                        onSaveResults={diagnosticBinocularFinished}
                        diagnosticChange={diagnosticChange}
                        onToggleCalibration={onToggleCalibration}
                        isCalibrationReady={isCalibrationReady}
                        onToggleCalibrationReady={onToggleCalibrationReady}
                    />
                );
            default:
                return null;
        }
    };

    const handleChangeDiagonal = (value: string) => {
        setPatientDiagonal(value);
    };

    const handleDistanceSuccess = () => {
        setShowEditPatientDiagonalModal(false);

        send('/msg/gateway-ws/message', '/procedure/' + participant?.fhirId, {
            type: WsProcedureTopicType.screenSettingsChange,
            body: { diagonal: patientDiagonal },
        });
    };

    return !viewOnlyData ? (
        <div className={classNames(styles.wrapper, !!activeScreenSharingParticipant && styles.screenSharing)}>
            {participantInLobby && (
                <div className={styles.notificationModal}>
                    <div className={styles.avatar}>
                        <ConsultationUserIcon className={styles.icon} />
                    </div>
                    <p>{t("consultation_session.patient_in_wait_room")}</p>
                    <Button type="primary" onClick={() => onApproveFromLobby?.(participantInLobby.id!)}>
                        {t("consultation_session.admit_patient_button")}
                    </Button>
                </div>
            )}

            {currentStep !== 100 && currentStep !== 200 && <div className={styles.fakeAside}></div>}
            {currentStep !== 100 && currentStep !== 200 && (
                <aside className={styles.aside}>
                    <div className={styles.content}>
                        <ul className={styles.steps}>
                            {availableSteps.map((step) => (
                                <li key={step.number}>
                                    <div
                                        className={classNames(
                                            styles.step,
                                            step.disabled && !step.confirmed && styles.disabled,
                                            currentStep === step.number && styles.active,
                                        )}
                                        onClick={() => changeStep(step.number)}
                                    >
                                        <div className={styles.stepIcon}></div>
                                        {step.number === 0 ? (
                                            <StepInfoIcon />
                                        ) : resultDocumentSigned ? (
                                            <StepSignedIcon />
                                        ) : step.confirmed ? (
                                            <StepDoneIcon />
                                        ) : (
                                            <StepDisabledIcon />
                                        )}

                                        {t(step.label)}

                                        {resultDocumentSigned && step.number > 0 && <LockIcon />}
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <div className={styles.actionsList}>
                            <Button
                                type="default"
                                disabled={
                                    !participant?.kurentoParticipant ||
                                    currentStep !== 2 || // only for StatusOculorumStep
                                    statusOculorumFormValue.diagnosticVisualAcuityMode !== 'auto'
                                }
                                onClick={() => setCurrentStep(100)}
                            >
                                {t("consultation_session.diagnostic_vision_button")}
                            </Button>
                            <Button
                                type="default"
                                disabled={
                                    !participant?.kurentoParticipant ||
                                    currentStep !== 2 || // only for StatusOculorumStep
                                    statusOculorumFormValue.diagnosticBinocularMode !== 'auto'
                                }
                                onClick={() => setCurrentStep(200)}
                            >
                                {t("consultation_session.diagnostic_binocular_button")}
                            </Button>
                            <Button
                                type="default"
                                onClick={() => setShowEditPatientDiagonalModal(true)}
                                disabled={!participantInLobby && !participant?.kurentoParticipant}
                            >
                                {t("consultation_session.set_patient_screen_button")}
                            </Button>
                            <Button type="default" onClick={() => releaseParticipant?.()} disabled={!participant?.kurentoParticipant}>
                                {t("consultation_session.release_patient_button")}
                            </Button>
                        </div>
                    </div>
                </aside>
            )}

            {/* render it with bigger zIndex to avoid diagnostic component destruction */}
            {activeScreenSharingParticipant && (
                <div className={styles.screenVideoWrapper}>
                    <div className={styles.participant_screenSharing} ref={screenSharingWrapperRef}></div>
                    <Button className={styles.disableScreenBtn} onClick={() => onRequestScreenSharing?.(false, activeScreenSharingParticipant)}>
                        <ScreenDisabledIcon />
                    </Button>
                </div>
            )}

            <div className={classNames(currentStep !== 100 && currentStep !== 200 ? styles.contentWrapper : styles.viewOnlyContent)}>
                {observationId && renderCurrentStepContent()}
            </div>

            {showEditPatientDiagonalModal && (
                <ScreenSettingsModal
                    title={t("consultation_session.set_patient_screen_button")}
                    diagonal={patientDiagonal}
                    onHandleChangeDiagonal={handleChangeDiagonal}
                    onCancel={() => setShowEditPatientDiagonalModal(false)}
                    onSuccess={handleDistanceSuccess}
                    isFirst={false}
                    showModal={true}
                />
            )}
        </div>
    ) : (
        <div className={styles.viewOnlyWrapper}>
            <aside className={styles.viewOnlyAside}>
                <ul className={styles.viewOnlySteps}>
                    {availableSteps.map((step) => (
                        <li key={step.number}>
                            <div
                                className={classNames(styles.step, step.disabled && styles.disabled, currentStep === step.number && styles.active)}
                                onClick={() => changeStep(step.number)}
                            >
                                {step.label}
                            </div>
                        </li>
                    ))}
                </ul>
            </aside>

            <div className={styles.viewOnlyContent}>{renderCurrentStepContent()}</div>
        </div>
    );
};
