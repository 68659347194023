import {
    addProfile,
    createUser,
    CreateUserDTO,
    CreateUserProfileDTO,
    getUsers,
    GetUsersParams,
    GetUsersProfileTypesItem,
    GetUsersStatus,
    UpdateUserDTOStatus,
    UserDTO,
    UserProfileDTOProfileType,
} from '@api/userServiceAPI';
import { CustomPhoneInput } from '@components';
import { SearchIcon } from '@icons';
import { PHONE_VALIDATION } from '@utils/constants';
import { Button, Col, Form, Input, Modal, Row, Select, Table, TablePaginationConfig, TableProps } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './ClinicAdminStaffPage.module.scss';
import SnilsInput, { SNILS_PATTERN } from 'src/components/SnilsInput/SnilsInput';

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

interface IAddEmployeeForm {
    lastName: string;
    firstName: string;
    middleName: string;
    mobile: string;
    email: string;
    role: string;
    //snils: string;
}

export const ClinicAdminStaffPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [filteredEmployeeList, setFilteredEmployeeList] = useState<UserDTO[]>([]);
    const [filterQuery, setFilterQuery] = useState<string | undefined>();
    const [filterRole, setFilterRole] = useState<string>('');
    const [rolesOptions, setRolesOptions] = useState<{ label: string; value: string | null }[]>([]);
    const [creatingRole, setCreatingRole] = useState<GetUsersProfileTypesItem>();
    const [goToEmployee, setGoToEmployee] = useState<boolean>(false);
    const [filterStatus, setFilterStatus] = useState<string>('');
    const [statusOptions, setStatusOptions] = useState<{ label: string; value: string | null }[]>([]);

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => <p>{t('adminEmployeePage.totalItems') + ' ' + total}</p>,
            position: ['bottomCenter'],
        },
    });

    const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
    const [addEmployeeForm] = Form.useForm();
    const [noMiddleNameFlag, setNoMiddleNameFlag] = useState(false);
    const { validateForm } = useFormValidation(addEmployeeForm);

    const debounceRef = useRef<any>(null);

    useEffect(() => {
        setRolesOptions([
            ...Object.keys(UserProfileDTOProfileType)
                .filter((role) => filterByRole(role as UserProfileDTOProfileType))
                .map((role: string) => ({
                    label: t(`adminMainPage.roles.${role}`),
                    value: role,
                })),
            { label: t('adminMainPage.filter.allRolesOption'), value: '' },
        ]);

        setStatusOptions([
            { label: t('adminMainPage.employeeTableColumns.activeStatus'), value: UpdateUserDTOStatus.ACTIVE },
            { label: t('adminMainPage.employeeTableColumns.nonActiveStatus'), value: UpdateUserDTOStatus.BLOCKED },
            { label: t('adminMainPage.employeeTableColumns.DRAFT'), value: UpdateUserDTOStatus.DRAFT },
            { label: t('adminMainPage.filter.allStatusesOption'), value: '' },
        ]);
    }, []);

    useEffect(() => {
        retrieveUsersToTable(0, tableParams.pagination?.pageSize || 10);
    }, [filterQuery, filterRole, filterStatus]);

    useEffect(() => {
        validate();
    }, [noMiddleNameFlag]);

    const handleChangeFilterQuery = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterQuery(e.target.value);
    };
    const handleSelectRole = (value: GetUsersProfileTypesItem) => {
        setFilterRole(value);
    };
    const handleSelectStatus = (value: GetUsersStatus) => {
        setFilterStatus(value);
    };

    const retrieveUsersToTable = (page: number, size: number, sorter?: SorterResult<UserDTO>) => {
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
            const searchCriteria: GetUsersParams = {
                page,
                size,
                search: filterQuery,
                status: filterStatus as GetUsersStatus,
                // todo sort after backend
                profileTypes: filterRole
                    ? [filterRole as GetUsersProfileTypesItem]
                    : Object.values(UserProfileDTOProfileType).filter((item) => filterByRole(item)),
            };

            if (sorter) {
                searchCriteria.sortField = (sorter as any).field || 'created';
                searchCriteria.sortDirection = (sorter as any).order === 'ascend' ? 'ASC' : 'DESC';
            }

            getUsers(searchCriteria, {
                paramsSerializer: {
                    encode: (params) => {
                        if (params === 'profileTypes[]') {
                            return 'profileTypes';
                        } else {
                            return params;
                        }
                    },
                },
            }).then((result) => {
                if (result?.data?.content) {
                    const data = result.data.content.map((user) => {
                        user.userProfileDTOList = user.userProfileDTOList?.filter((profile) => filterByRole(profile.profileType));
                        return user;
                    });
                    setTableParams({
                        pagination: { ...tableParams.pagination, pageSize: size, current: page + 1, total: result.data.totalElements },
                    });
                    setFilteredEmployeeList(data);
                }
            });
        }, 300);
    };

    const filterByRole = (role: UserProfileDTOProfileType | undefined): boolean => {
        return (
            !!role &&
            role != UserProfileDTOProfileType.End_user &&
            role != UserProfileDTOProfileType.Patient &&
            role != UserProfileDTOProfileType.Administrator
        );
    };

    const noMiddleNameHandler = (evt: CheckboxChangeEvent) => {
        if (addEmployeeForm && evt.target.checked && addEmployeeForm.getFieldValue('middleName')) {
            addEmployeeForm.setFields([{ name: 'middleName', value: null, errors: [] }]);
        }
        setNoMiddleNameFlag(evt.target.checked);
        addEmployeeForm?.validateFields(['middleName']);
    };

    const addEmployee = (formData: IAddEmployeeForm) => {
        if (!addEmployeeForm) {
            return;
        }
        addEmployeeForm.validateFields();

        setTimeout(() => {
            if (!addEmployeeForm.getFieldsError().find(({ errors }: { errors: string[] }) => errors.length > 0)) {
                const newUser: CreateUserDTO = {
                    firstName: formData.firstName,
                    middleName: formData.middleName,
                    lastName: formData.lastName,
                    mobile: formData.mobile,
                    email: formData.email,
                    //snils: formData.snils,
                };

                createUser(newUser)
                    .then((result) => {
                        if (result.data?.id) {
                            const newProfile: CreateUserProfileDTO = {
                                firstName: formData.firstName,
                                middleName: formData.middleName,
                                lastName: formData.lastName,
                                email: formData.email,
                                phone: formData.mobile,
                                profileType: GetUsersProfileTypesItem[formData.role as GetUsersProfileTypesItem],
                                //snils: formData.snils
                            };
                            const newEmployeeId = result.data?.id;
                            addProfile(result.data.id, newProfile).then((result) => {
                                if (result) {
                                    if (goToEmployee) {
                                        navigate(`/staff/${newEmployeeId}?profileTab=true`);
                                    } else {
                                        retrieveUsersToTable((tableParams.pagination?.current || 1) - 1, tableParams.pagination?.pageSize || 10);
                                    }
                                }
                            });
                        }
                    })
                    .then((result) => {
                        addEmployeeForm.resetFields();
                        setAddEmployeeModalOpen(false);
                    });
            }
        });
    };

    const handleTableChange: TableProps<UserDTO>['onChange'] = (pagination, filters, sorter) => {
        retrieveUsersToTable((pagination.current || 1) - 1, pagination?.pageSize || 10, sorter as SorterResult<UserDTO>);
    };

    const employeeTableColumns: ColumnsType<UserDTO> = [
        {
            title: t('adminMainPage.employeeTableColumns.name'),
            dataIndex: ['firstName', 'lastName', 'middleName'],
            render: (text, record) => {
                let result = '';
                if (record.lastName) {
                    result += record.lastName;
                }
                if (record.firstName) {
                    result += ' ' + record.firstName;
                }
                if (record.middleName) {
                    result += ' ' + record.middleName;
                }
                return result || '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.lastName) {
            //         return 1;
            //     }
            //     if (!b.lastName) {
            //         return -1;
            //     }
            //     if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'name',
        },
        {
            title: t('adminMainPage.employeeTableColumns.role'),
            dataIndex: 'profileType',
            render: (text, record) => {
                if (record.userProfileDTOList) {
                    return record.userProfileDTOList[0]?.profileType ? t(`adminMainPage.roles.${record.userProfileDTOList[0]?.profileType}`) : '-';
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.userProfileDTOList || !a.userProfileDTOList[0]?.profileType) {
            //         return 1;
            //     }
            //     if (!b.userProfileDTOList || !b.userProfileDTOList[0]?.profileType) {
            //         return -1;
            //     }
            //     if (
            //         t(`adminMainPage.roles.${a.userProfileDTOList[0]?.profileType}`) >
            //         t(`adminMainPage.roles.${b.userProfileDTOList[0]?.profileType}`)
            //     ) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'role',
        },
        {
            title: t('adminMainPage.employeeTableColumns.registered'),
            dataIndex: 'created',
            render: (created) => {
                return format(new Date(created), 'dd.MM.yyyy HH:mm');
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.created) {
            //         return 1;
            //     }
            //     if (!b.created) {
            //         return -1;
            //     }
            //     if (new Date(a.created) > new Date(b.created)) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'registered',
        },
        {
            title: t('adminMainPage.employeeTableColumns.status'),
            dataIndex: 'status',
            render: (_status, record) => {
                // TODO set correct status on the backend
                const status = record.userProfileDTOList?.some(
                    (x) =>
                        x.profileType === UserProfileDTOProfileType.Nurse ||
                        x.profileType === UserProfileDTOProfileType.Practitioner ||
                        x.profileType === UserProfileDTOProfileType.Head_physician,
                )
                    ? _status || 'DRAFT'
                    : _status || 'ACTIVE';

                return <p className={classNames(styles.status, styles[status])}>{t('adminMainPage.employeeTableColumns.' + status, '-')}</p>;
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.status || a.status == UpdateUserDTOStatus.BLOCKED) {
            //         return 1;
            //     }
            //     if (!b.status || b.status == UpdateUserDTOStatus.ACTIVE) {
            //         return -1;
            //     }
            //     return -1;
            // },
            key: 'status',
        },
    ];

    const onOpenEmployeePage = (record: UserDTO) => {
        navigate(`/staff/${record.id}`);
    };

    const validate = () => {
        const fieldsToCheck = ['lastName', 'firstName', 'mobile', 'email', 'role'];
        if (!noMiddleNameFlag) {
            fieldsToCheck.push('middleName');
        }
        validateForm(fieldsToCheck);
    };

    const handleChooseRole = (value: GetUsersProfileTypesItem) => {
        setCreatingRole(value);
    };

    return (
        <div className={styles['wrapper']}>
            <div className="d-flex justify-content-between">
                <p className={styles.title}>{t('adminMainPage.title')}</p>
                <Button className={styles.addEmployeeButton} onClick={() => setAddEmployeeModalOpen(true)} type="primary">
                    {t('adminMainPage.addEmployee')}
                </Button>
            </div>
            <div className={styles.filterBlock}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Input placeholder={t('adminMainPage.filter.searchPlaceholder')} prefix={<SearchIcon />} onChange={handleChangeFilterQuery} />
                    </Col>
                    <Col span={4}>
                        <Select
                            placeholder={t('adminMainPage.filter.rolesPlaceholder')}
                            value={filterRole as GetUsersProfileTypesItem}
                            onChange={handleSelectRole}
                            options={rolesOptions}
                            className="w-100"
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            placeholder={t('adminMainPage.filter.statusesPlaceholder')}
                            value={filterStatus as GetUsersStatus}
                            onChange={handleSelectStatus}
                            options={statusOptions}
                            className="w-100"
                        />
                    </Col>
                </Row>
            </div>
            <div className="mt-4">
                <Table
                    className={styles.employeeRow}
                    rowKey={(record) => record.id!}
                    columns={employeeTableColumns}
                    dataSource={filteredEmployeeList}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onOpenEmployeePage(record);
                            },
                        };
                    }}
                />
            </div>

            <Modal
                width={720}
                title={t('adminMainPage.modals.addEmployee.title')}
                open={addEmployeeModalOpen}
                onOk={() => addEmployeeForm.submit()}
                onCancel={() => setAddEmployeeModalOpen(false)}
                forceRender
                footer={[
                    <div key="empty" />,
                    <div key="group">
                        <Button key="cancel" onClick={() => setAddEmployeeModalOpen(false)}>
                            {t('adminMainPage.modals.common.cancel')}
                        </Button>
                        <Button type="primary" key="success" onClick={() => addEmployeeForm.submit()}>
                            {t('adminMainPage.modals.common.add')}
                        </Button>
                    </div>,
                ]}
            >
                <Form
                    form={addEmployeeForm}
                    onFinish={addEmployee}
                    name="addEmployeeForm"
                    layout="vertical"
                    wrapperCol={{ span: 24 }}
                    initialValues={{
                        lastName: '',
                        firstName: '',
                        middleName: '',
                        mobile: '',
                        email: '',
                        role: null,
                    }}
                    autoComplete="off"
                    requiredMark={false}
                    onChange={validate}
                >
                    <Row gutter={24}>
                        <Col md={8} sm={24} xs={24}>
                            <Form.Item
                                name="lastName"
                                label={t('adminMainPage.modals.addEmployee.lastName')}
                                rules={[{ required: true, message: t('adminMainPage.validation.lastNameIsRequired') }]}
                            >
                                <Input autoFocus />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                            <Form.Item
                                name="firstName"
                                label={t('adminMainPage.modals.addEmployee.firstName')}
                                rules={[{ required: true, message: t('adminMainPage.validation.firstNameIsRequired') }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                            <Form.Item
                                name="middleName"
                                className={styles.labelW100}
                                label={
                                    <Row className="w-100" justify="space-between">
                                        <Col>{t('adminMainPage.modals.addEmployee.middleName')}</Col>
                                        <Col>
                                            <Checkbox
                                                className={classNames(styles.smallCheckBox, styles.checkboxItem)}
                                                onChange={noMiddleNameHandler}
                                                checked={noMiddleNameFlag}
                                            >
                                                {t('adminMainPage.modals.addEmployee.noMiddleName')}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                }
                                rules={[{ required: !noMiddleNameFlag, message: t('adminMainPage.validation.middleNameIsRequired') }]}
                            >
                                <Input disabled={noMiddleNameFlag} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                name="mobile"
                                label={t('adminMainPage.modals.addEmployee.mobile')}
                                rules={[
                                    { required: true, message: t('adminMainPage.validation.mobileIsRequired') },
                                    {
                                        pattern: new RegExp(PHONE_VALIDATION),
                                        message: t('adminMainPage.validation.mobileFormat'),
                                    },
                                ]}
                            >
                                <CustomPhoneInput />
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={t('adminMainPage.modals.addEmployee.email')}
                                name="email"
                                validateTrigger={'onBlur'}
                                rules={[
                                    { type: 'email', message: t('adminMainPage.validation.wrongEmail') },
                                    { required: true, message: t('adminMainPage.validation.emailIsRequired') },
                                ]}
                            >
                                <Input autoFocus />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        {/*{false && (
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                className={styles.bottomSection}
                                name="snils"
                                rules={[
                                    { required: true, message: t('adminMainPage.validation.snilsIsRequired') },
                                    {
                                        pattern: SNILS_PATTERN,
                                        message: t('adminMainPage.validation.snilsWrongFormat'),
                                    },
                                ]}
                                label={t('adminMainPage.modals.addEmployee.snils')}
                            >
                                <SnilsInput
                                    value={addEmployeeForm.getFieldValue('snils')}
                                    onChange={e => addEmployeeForm.setFieldsValue({ snils: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                        )}*/}
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                name="role"
                                label={t('adminMainPage.modals.addEmployee.role')}
                                rules={[{ required: true, message: t('adminMainPage.validation.roleIsRequired') }]}
                            >
                                <Select
                                    placeholder={t('adminMainPage.modals.addEmployee.rolesPlaceholder')}
                                    options={rolesOptions.filter((option) => option.value !== '')}
                                    dropdownStyle={{ zIndex: 99999 }}
                                    className="w-100"
                                    onChange={handleChooseRole}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {(creatingRole === UserProfileDTOProfileType.Nurse ||
                            creatingRole === UserProfileDTOProfileType.Practitioner ||
                            creatingRole === UserProfileDTOProfileType.Head_physician) && (
                            <Col md={12} sm={24} xs={24}>
                                <Checkbox onChange={() => setGoToEmployee(!goToEmployee)}>
                                    <p>{t("clinic_admin_staff.go_to_staff_profile")}</p>
                                </Checkbox>
                            </Col>
                    )}
                </Form>
            </Modal>
        </div>
    );
};
