import {getPatientUnhandledMessagesCount, getPractitionerProfile, getUnhandledMessagesCount} from '@api/mainServiceAPI';
import {
    CheckCodeResponseDTODataMode,
    EsiaContactDTO,
    EsiaContactDTOType,
    UserOfferRecoverPasswordDTOChannel,
    UserProfileDTOProfileType,
    authenticate,
    getCurrentUser,
    getLogoutUserUrl,
    getUserInfo,
    getUserUrl,
    initRegister,
    logout,
    offerRecoverPassword,
    offerRecoverPassword1,
    offerRecoverPassword2,
    offerRegister,
    register,
    sendSmsCode,
    verifySmsCode,
} from '@api/userServiceAPI';
import {createListenerMiddleware} from '@reduxjs/toolkit';
import {
    ACCOUNT_ALREADY_EXISTS,
    APPROVE_ACTION_INVALID_CODE,
    EMAIL_ALREADY_IN_USE,
    INVALID_SMS_CODE,
    PHONE_ALREADY_IN_USE,
    StorageKeys,
    USER_NOT_FOUND,
} from '@utils/constants';
import {AxiosError} from 'axios';
import {RootState} from '../Store';
import {
    confirmPhone,
    getAuthUser,
    getDocInfoModel,
    getLinkEsia,
    getUnhandledMsgs,
    getUserInfoEsia,
    initRegisterAction,
    login,
    logoutLocal,
    passwordRecovered,
    phoneConfirmed,
    proceedRegistration,
    proceedRestorePassword,
    registered,
    registerEsia, registerWithoutPhoneConfirmation,
    registrationInitDone,
    remoteValidationError,
    restorePassword,
    restorePasswordConfirmCode,
    restorePwdInitDone,
    saveRecoveredPassword,
    sendEmail,
    sendSMS,
    setAuthUser,
    setCurrentProfile,
    setDocInfoModel,
    setEmail,
    setLinkEsia,
    setPhone,
    setRegistrationUserEsia,
    setSwitchedToEndUser,
    setUnhandledMsgs,
    smsSent,
    storeFormData,
    switchToEndUser,
} from './slice';
import {UserRole} from './state';
import dayjs from 'dayjs';
import {EsiaLoginLocalStorage} from 'src/services/local-storage/EsiaLoginLocalStorage';

export const userListenerMiddleware = createListenerMiddleware();

userListenerMiddleware.startListening({
    actionCreator: login,
    effect: ({payload}, listenerApi) => {
        authenticate({username: payload.login, password: payload.password}).then(() => {
            listenerApi.dispatch(getAuthUser({}));
        });
    },
});

userListenerMiddleware.startListening({
    actionCreator: logoutLocal,
    effect: ({payload}, listenerApi) => {
        const logoutFunc = () => {
            logout().then(() => {
                listenerApi.dispatch(setCurrentProfile(null));
                listenerApi.dispatch(setAuthUser(null));
            });
        }
        logoutFunc();

        /*if (payload.isEsia) {
            getLogoutUserUrl().then((res) => {
                if (res.data) {
                    window.location.href = res.data;
                }
                logoutFunc();
            });
        } else {
            logoutFunc();
        }*/

    },
});

userListenerMiddleware.startListening({
    actionCreator: getAuthUser,
    effect: ({payload}, listenerApi) => {
        getCurrentUser()
            .then((x) => {
                const user = x?.data;
                const userProfile = user?.userProfileDTOList?.find((x) => x.profileType !== UserRole.End_user);

                if (userProfile) {
                    listenerApi.dispatch(
                        setAuthUser({
                            ...user,
                            role: userProfile.profileType,
                        }),
                    );

                    if (userProfile.profileType === UserRole.Patient) {
                        const setDefaultPatient = () => {
                            const lastPatient = user.userProfileDTOList?.filter((x) => x.profileType === UserProfileDTOProfileType.Patient).pop();
                            if (lastPatient) {
                                listenerApi.dispatch(setCurrentProfile(lastPatient));
                            }
                        };

                        // Set current patient
                        if (payload.newProfileAdded) {
                            setDefaultPatient();
                        } else {
                            const currentPatientId = localStorage.getItem(StorageKeys.currentPatientId);
                            const patient = user.userProfileDTOList?.find((x) => x.fhirId === currentPatientId);

                            if (patient) {
                                listenerApi.dispatch(setCurrentProfile(patient));
                            } else {
                                // setDefaultPatient();
                                listenerApi.dispatch(
                                    setAuthUser({
                                        ...user,
                                        role: UserRole.End_user,
                                    }),
                                );

                                if (location.pathname === '/create-patient' || location.pathname === '/profile') {
                                    listenerApi.dispatch(switchToEndUser(location.pathname));
                                } else {
                                    listenerApi.dispatch(switchToEndUser('/profile'));
                                }
                            }
                        }
                    } else {
                        listenerApi.dispatch(setCurrentProfile(userProfile));
                    }
                } else {
                    listenerApi.dispatch(
                        setAuthUser({
                            ...user,
                            role: UserRole.End_user,
                        }),
                    );

                    listenerApi.dispatch(switchToEndUser('/create-patient'));
                }
            })
            .catch(() => {
                // it's a dirty hack
                listenerApi.dispatch(setAuthUser(null));
            });
    },
});

userListenerMiddleware.startListening({
    actionCreator: setCurrentProfile,
    effect: ({payload}, listenerApi) => {
        // todo now only for patients
        if (payload?.fhirId) {
            listenerApi.dispatch(setSwitchedToEndUser(false));
            localStorage.setItem(StorageKeys.currentPatientId, payload.fhirId);
        } else {
            // localStorage.removeItem(StorageKeys.currentPatientId);
        }
    },
});

userListenerMiddleware.startListening({
    actionCreator: switchToEndUser,
    effect: ({payload}, listenerApi) => {
        listenerApi.dispatch(setSwitchedToEndUser(true));
        localStorage.removeItem(StorageKeys.currentPatientId);
    },
});

userListenerMiddleware.startListening({
    actionCreator: sendEmail,
    effect: ({payload}, listenerApi) => {
        offerRegister({...payload})
            .then((resp) => {
                listenerApi.dispatch(proceedRegistration(resp.data));
            })
            .catch((error: AxiosError<{ code?: string }>) => {
                if (error.response?.data.code === EMAIL_ALREADY_IN_USE) {
                    listenerApi.dispatch(remoteValidationError({error: `apiErrors.${EMAIL_ALREADY_IN_USE}`, field: 'email'}));
                }
            });
    },
});

userListenerMiddleware.startListening({
    actionCreator: restorePassword,
    effect: ({payload}, listenerApi) => {
        offerRecoverPassword1({channel: UserOfferRecoverPasswordDTOChannel.EMAIL, emailOrPhone: payload.email})
            .then((resp) => {
                listenerApi.dispatch(proceedRestorePassword(resp.data));
            })
            .catch((error: AxiosError<{ code?: string }>) => {
                switch (error.response?.data.code) {
                    case EMAIL_ALREADY_IN_USE:
                        listenerApi.dispatch(remoteValidationError({error: `apiErrors.${EMAIL_ALREADY_IN_USE}`, field: 'email'}));
                        break;
                    case USER_NOT_FOUND:
                        listenerApi.dispatch(remoteValidationError({error: `apiErrors.${USER_NOT_FOUND}`, field: 'email'}));
                }
            });
    },
});

userListenerMiddleware.startListening({
    actionCreator: initRegisterAction,
    effect: ({payload}, listenerApi) => {
        initRegister({code: payload.code, sessionId: payload.sessionId})
            .then((resp) => {
                if (resp.data.dataMode === CheckCodeResponseDTODataMode.TOKEN) {
                    listenerApi.dispatch(registrationInitDone(resp.data));
                }
            })
            .catch((error: AxiosError<{ code?: string; message?: string }>) => {
                if (error.response?.data.code === APPROVE_ACTION_INVALID_CODE) {
                    listenerApi.dispatch(remoteValidationError({error: `apiErrors.${INVALID_SMS_CODE}`, field: 'emailCode'}));
                }
            });
    },
});

userListenerMiddleware.startListening({
    actionCreator: restorePasswordConfirmCode,
    effect: ({payload}, listenerApi) => {
        offerRecoverPassword2({code: payload.code, sessionId: payload.sessionId})
            .then((resp) => {
                if (resp.data.dataMode === CheckCodeResponseDTODataMode.TOKEN) {
                    listenerApi.dispatch(restorePwdInitDone(resp.data));
                }
            })
            .catch((error: AxiosError<{ code?: string; message?: string }>) => {
                if (error.response?.data.code === APPROVE_ACTION_INVALID_CODE) {
                    listenerApi.dispatch(remoteValidationError({error: `apiErrors.${INVALID_SMS_CODE}`, field: 'emailCode'}));
                }
            });
    },
});

userListenerMiddleware.startListening({
    actionCreator: sendSMS,
    effect: ({payload}, listenerApi) => {
        sendSmsCode(payload)
            .then((resp) => {
                listenerApi.dispatch(smsSent(resp.data));
            })
            .catch((error: AxiosError<{ code?: string }>) => {
                // TODO PHONE_ALREADY_IN_USE is the expected value but is to be implemented yet:
                if (error.response?.data.code === ACCOUNT_ALREADY_EXISTS || error.response?.data.code === PHONE_ALREADY_IN_USE) {
                    listenerApi.dispatch(remoteValidationError({error: `apiErrors.${PHONE_ALREADY_IN_USE}`, field: 'mobile'}));
                }
            });
    },
});

userListenerMiddleware.startListening({
    actionCreator: confirmPhone,
    effect: ({payload}, listenerApi) => {
        verifySmsCode({code: payload.code, sessionId: payload.sessionId})
            .then((resp) => {
                if (resp.data.dataMode === CheckCodeResponseDTODataMode.TOKEN) {
                    listenerApi.dispatch(phoneConfirmed(resp.data));

                    /*register({ ...payload.accountFormData, emailTokenAfter: payload.emailTokenAfter, phoneTokenAfter: resp.data.dataOrToken }).then(
                        () => {
                            listenerApi.dispatch(registered());
                        },
                    );*/
                }
            })
            .catch((error: AxiosError<{ code?: string; message?: string }>) => {
                if (error.response?.data.code === APPROVE_ACTION_INVALID_CODE) {
                    listenerApi.dispatch(remoteValidationError({error: `apiErrors.${INVALID_SMS_CODE}`, field: 'smsCode'}));
                }
            });
    },
});

userListenerMiddleware.startListening({
    actionCreator: registerWithoutPhoneConfirmation,
    effect: ({payload}, listenerApi) => {
        register({...payload.accountFormData, emailTokenAfter: payload.emailTokenAfter}).then(
            () => {
                listenerApi.dispatch(registered());
            },
        );
    },
});

userListenerMiddleware.startListening({
    actionCreator: saveRecoveredPassword,
    effect: ({payload}, listenerApi) => {
        offerRecoverPassword({newPassword: payload.newPassword, token: payload.token}).then(() => {
            listenerApi.dispatch(passwordRecovered());
        });
    },
});

userListenerMiddleware.startListening({
    actionCreator: getDocInfoModel,
    effect: ({payload}, listenerApi) => {
        getPractitionerProfile(payload).then((result) => {
            if (result?.data) {
                listenerApi.dispatch(setDocInfoModel(result.data));
            }
        });
    },
});

userListenerMiddleware.startListening({
    actionCreator: getUnhandledMsgs,
    effect: (payload, listenerApi) => {
        const user = (listenerApi.getState() as RootState)?.userReducer;
        const profileId = user.currentProfile?.fhirId;
        if (profileId) {
            const getUnhandledMsgCountMethod =
                user.authUser?.role === UserRole.Patient ? getPatientUnhandledMessagesCount : getUnhandledMessagesCount;
            getUnhandledMsgCountMethod(profileId).then((res) => {
                if (res?.data) {
                    listenerApi.dispatch(setUnhandledMsgs(res.data));
                }
            });
        }
    },
});

userListenerMiddleware.startListening({
    actionCreator: registerEsia,
    effect: ({payload}, listenerApi) => {
        /*register({...payload.user, emailTokenAfter: payload.email, phoneTokenAfter: payload.phone}).then((data: any) => {
            listenerApi.dispatch(getAuthUser({}));
        });*/
    },
});

userListenerMiddleware.startListening({
    actionCreator: getLinkEsia,
    effect: ({payload}, listenerApi) => {
        getUserUrl().then((res) => {
            if (res?.data) {
                listenerApi.dispatch(setLinkEsia(res.data));
            }
        });
    },
});

userListenerMiddleware.startListening({
    actionCreator: getUserInfoEsia,
    effect: ({payload}, listenerApi) => {
        getUserInfo({code: payload}).then((res) => {
            EsiaLoginLocalStorage.setData(res.data);
            if (!res?.data.personRoot?.userAlreadyRegistered) {
                const {birthDate, firstName, lastName, middleName, snils} = {...res.data.personRoot};

                const mobile = res.data.contacts?.find((contact: EsiaContactDTO) => contact.type === EsiaContactDTOType.MBT)?.value;
                const email = res.data.contacts?.find((contact: EsiaContactDTO) => contact.type === EsiaContactDTOType.EML)?.value;

                listenerApi.dispatch(storeFormData({
                    birthDate: dayjs(birthDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    firstName: firstName,
                    lastName: lastName,
                    middleName: middleName,
                    snils: snils,
                    isEsia: true,
                }));

                mobile && listenerApi.dispatch(setPhone({phone: mobile}));
                email && listenerApi.dispatch(setEmail({email: email}));

                listenerApi.dispatch(setRegistrationUserEsia(true));
            } else {
                listenerApi.dispatch(getAuthUser({}));
            }
        });
    },
});