import { AppointmentScheduleDTO, EncounterDTODetails, getAvailableSlots, getPractitionerProfile } from '@api/mainServiceAPI';
import { ScheduleDoctorIcon, UserIcon } from '@icons';
import { TextField } from '@mui/material';
import { CalendarPicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { UserRole, selectAuthUser } from '@sliceUser';
import { END_DAY_TIME } from '@utils/constants';
import { dateForServer } from '@utils/utils';
import { Avatar, Button, Col, List, Modal, Row, Select, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EncounterChangeReasonEnum } from 'src/enums/encounter-change-reason.enum';
import styles from './AppointmentChangeConsultationModal.module.scss';
import { AboutPractitionerDTO, UserProfileDetailsDTO } from '@api/userServiceAPI';
import { getPractitioners, selectPractitioners } from '@sliceConsultation';
import { DoctorList } from 'src/components/DoctorList/DoctorList';
const { Option } = Select;

export interface IDiagonalProps {
    onHandleChangeDiagonal: (value: string) => void;
    diagonal: string;
}

interface IScreenSettingsProps {
    showModal: boolean;
    onClose: () => void;
    onCancelConsultation: (reason: EncounterChangeReasonEnum) => void;
    onChangeConsultation: (slot: AppointmentScheduleDTO, reasonType: EncounterChangeReasonEnum, reasonDescription: string) => void;
    encounter: EncounterDTODetails | undefined;
    isPatient?: boolean;
}

export const AppointmentChangeConsultationModal = ({
    showModal,
    onClose,
    onCancelConsultation,
    onChangeConsultation,
    encounter,
    isPatient = false,
}: IScreenSettingsProps) => {
    const { t } = useTranslation();
    const currentUser = useSelector(selectAuthUser);
    const dispatch = useDispatch();

    const [selectedSlot, setSelectedSlot] = useState<AppointmentScheduleDTO | undefined>();
    const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>();
    const [selectedReason, setSelectedReason] = useState<EncounterChangeReasonEnum>();
    const [reasonDescription, setReasonDescription] = useState<string>('');
    const [changeReasonState, setChangeReasonState] = useState<boolean>(false);
    const [cancelReasonState, setCancelReasonState] = useState<boolean>(false);
    const [availableSlots, setAvailableSlots] = useState<AppointmentScheduleDTO[]>([]);
    const [selectPractitionerMode, setSelectPractitionerMode] = useState<boolean>(false);
    const [currentPractitionerProfile, setCurrentPractitionerProfile] = useState<UserProfileDetailsDTO>();
    const doctorsList = useSelector(selectPractitioners);
    
    const [morning, setMorning] = useState<AppointmentScheduleDTO[]>();
    const [day, setDay] = useState<AppointmentScheduleDTO[]>();
    const [evening, setEvening] = useState<AppointmentScheduleDTO[]>();

    useEffect(() => {
        if(currentUser?.role === UserRole.Call_center_manager && !doctorsList.length && encounter?.encounterDetailDTO?.patientFhirId) {
            dispatch(
                getPractitioners({
                    userId: encounter?.encounterDetailDTO?.patientFhirId,
                    page: 0,
                    size: 999999,
                    chiefComplaintCodes: currentPractitionerProfile?.practitionerChiefComplaintCodes,
                    priceCategoryType: currentPractitionerProfile?.priceCategory
                }),
            );
        }
    }, [currentPractitionerProfile]);

    useEffect(() => {
        if(currentUser?.role === UserRole.Call_center_manager && encounter?.encounterDetailDTO?.practitionerFhirId) {
            getPractitionerProfile(encounter?.encounterDetailDTO?.practitionerFhirId).then((response: any) => {
                setCurrentPractitionerProfile(response.data);
            });
        }
    }, []);


    useEffect(() => {
        if (encounter?.encounterDetailDTO?.dateTime) {
            handleMonthChange(dayjs(encounter.encounterDetailDTO.dateTime), true);
        }
    }, []);

    const handleDoctorChange = (practitioner?: AboutPractitionerDTO) => {
        if(practitioner && encounter && encounter.encounterDetailDTO) {
            encounter.encounterDetailDTO.practitionerFhirId = practitioner.id;
            encounter.encounterDetailDTO.practitionerName = `${practitioner.firstName} ${practitioner.middleName} ${practitioner.lastName}`;

            handleMonthChange(dayjs(encounter.encounterDetailDTO.dateTime), true);
            setSelectPractitionerMode(false);
        }
    }

    const handleMonthChange = (date: dayjs.Dayjs | undefined, isInitialDate?: boolean) => {
        if (date) {
            getAvailableSlots([
                {
                    startDate: dateForServer(date.startOf('month').toDate(), true, true),
                    endDate: dateForServer(date.endOf('month').toDate(), true, true) + END_DAY_TIME,
                    practitionerId: encounter?.encounterDetailDTO?.practitionerFhirId,
                    serviceType: encounter?.encounterDetailDTO?.serviceType,
                    roleCode: encounter?.encounterDetailDTO?.practitionerRoleCode ? [encounter?.encounterDetailDTO?.practitionerRoleCode] : [],
                    serviceCategory: encounter?.encounterDetailDTO?.serviceType === 'consultation' ? undefined : encounter?.partOf,
                    diagnosis: encounter?.encounterDetailDTO?.diagnosis,
                },
            ]).then((result) => {
                if (result.data) {
                    setAvailableSlots(result.data);
                    if (isInitialDate) {
                        handleDateChange(date, result.data);
                    }
                }
            });
        }
    };

    const handleDateChange = (date: dayjs.Dayjs | null | undefined, slots: AppointmentScheduleDTO[] = availableSlots) => {
        if (date) {
            setSelectedDate(date);

            const timeInRange = (dateTime: number, start: string, end: string) => {
                const time = dayjs(dateTime).format('HH:mm');
                return time > start && time < end;
            };

            const currentDaySlots = slots.filter((x) => dayjs(x.dateTime).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) || [];

            setMorning(currentDaySlots.filter((x) => timeInRange(x.dateTime!, '00:00', '12:00')));
            setDay(currentDaySlots.filter((x) => timeInRange(x.dateTime!, '12:00', '18:00')));
            setEvening(currentDaySlots.filter((x) => timeInRange(x.dateTime!, '18:00', '24:00')));
        }
    };

    const confirmDateChange = () => {
        if (!changeReasonState) {
            setChangeReasonState(true);
            setCancelReasonState(false);
            // todo update slot for encounter
        } else {
            if (selectedSlot && selectedReason) {
                onChangeConsultation(selectedSlot, selectedReason, reasonDescription);
                onModalClose();
            }
        }
    };

    const onOpenCancelReason = () => {
        setCancelReasonState(true);
        setChangeReasonState(false);
    };

    const onCancelEvent = () => {
        if (selectedReason) {
            onCancelConsultation(selectedReason);
            onModalClose();
        }
    };

    const onModalClose = () => {
        setChangeReasonState(false);
        setCancelReasonState(false);
        onClose();
    };

    const handleSelectSlot = (slot: AppointmentScheduleDTO) => {
        setSelectedSlot(slot);
    };

    return (
        <Modal
            width={800}
            title={cancelReasonState ? t('modals.changeConsultation.cancelConsultationTitle') : t('modals.changeConsultation.title')}
            open={showModal}
            onCancel={onModalClose}
            footer={
                selectPractitionerMode 
                    ? 
                        [
                            <div key="leftEmpty"></div>,
                            <Button onClick={() => setSelectPractitionerMode(false)} key="back">
                                {t('modals.changeConsultation.return')}
                            </Button>
                        ]
                    : cancelReasonState
                        ? [
                            <div key="leftEmpty"></div>,
                            <div key="block" className="d-flex">
                                <Button onClick={() => onModalClose()} key="back">
                                    {t('modals.changeConsultation.return')}
                                </Button>
                                <Button onClick={onCancelEvent} key="cancel" type="primary">
                                    {t('modals.changeConsultation.cancelConfirm')}
                                </Button>
                            </div>,
                        ]
                        : [
                            <Button key="support" type="ghost" style={{ color: 'red' }} onClick={() => onOpenCancelReason()}>
                                {t('modals.changeConsultation.cancelConsultation')}
                            </Button>,
                            <div key="block" className="d-flex">
                                <Button onClick={() => onModalClose()} key="back">
                                    {t('modals.changeConsultation.return')}
                                </Button>
                                <Button onClick={confirmDateChange} disabled={!selectedSlot} key="submit" type="primary">
                                    {t('modals.changeConsultation.confirm')}
                                </Button>
                            </div>,
                        ]
            }
        >
            {selectPractitionerMode ? (
                <DoctorList 
                    listItemClass={styles.changeDoctorListItem}
                    doctors={doctorsList}
                    disabledChooseBtn={false}
                    onChooseDoctor={handleDoctorChange}
                    showMoreBtn={false}
                    showPrice={false}
                />
            ) : 
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={styles.content}>
                        <Row gutter={24}>
                            {changeReasonState ? (
                                <div>
                                    <p>
                                        {t('modals.changeConsultation.confirmQuestion') +
                                            ' «' +
                                            t(`callCenterManager.encounterTypes.${encounter?.encounterDetailDTO?.appointmentType}`) +
                                            '» ' +
                                            t('modals.changeConsultation.patient') +
                                            ' ' +
                                            encounter?.encounterDetailDTO?.patientName +
                                            ':'}
                                    </p>
                                    <div className="d-flex mt-2">
                                        <p className={styles.changePrefix}>{t('modals.changeConsultation.from')}:</p>
                                        <p>
                                            {t('modals.changeConsultation.practitioner') +
                                                ' ' +
                                                encounter?.encounterDetailDTO?.practitionerName +
                                                ' (' +
                                                t(`appointment.practitionerRoles.${encounter?.encounterDetailDTO?.practitionerRoleCode}`) +
                                                '), ' +
                                                dayjs(encounter?.encounterDetailDTO?.dateTime).format('DD.MM.YYYY HH:mm')}
                                        </p>
                                    </div>
                                    <div className="d-flex mt-2">
                                        <p className={styles.changePrefix}>{t('modals.changeConsultation.to')}:</p>
                                        <p>
                                            {t('modals.changeConsultation.practitioner') +
                                                ' ' +
                                                encounter?.encounterDetailDTO?.practitionerName +
                                                ' (' +
                                                t(`appointment.practitionerRoles.${encounter?.encounterDetailDTO?.practitionerRoleCode}`) +
                                                '), ' +
                                                dayjs(selectedSlot?.dateTime)?.format('DD.MM.YYYY HH:mm')}
                                        </p>
                                    </div>
                                    <div className={styles.reasonBlock}>
                                        <p className={styles.title}>{t('modals.changeConsultation.reason')}</p>

                                        <Select className="w-100" onChange={(value) => setSelectedReason(value)}>
                                            {(isPatient
                                                ? Object.keys(EncounterChangeReasonEnum).filter(
                                                    (r) =>
                                                        r === EncounterChangeReasonEnum.patient_request ||
                                                        r === EncounterChangeReasonEnum.patient_illness ||
                                                        r === EncounterChangeReasonEnum.technical_problem_on_patient_side ||
                                                        r === EncounterChangeReasonEnum.patient_refuse,
                                                )
                                                : Object.keys(EncounterChangeReasonEnum)
                                            ).map((x) => (
                                                <Option key={x} value={x}>
                                                    {isPatient ? t('enums.patientEncounterChangeReason.' + x) : t('enums.encounterChangeReason.' + x)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className={styles.commentBlock}>
                                        <p className={styles.title}>{t('modals.changeConsultation.comment')}</p>
                                        <TextField onChange={(e) => setReasonDescription(e.target.value)} style={{ width: '100%', height: '64px' }} />
                                    </div>
                                </div>
                            ) : cancelReasonState ? (
                                <div>
                                    <p>
                                        {t('modals.changeConsultation.cancelConsultationDesc')}{' '}
                                        <span style={{ fontWeight: '600' }}>{t('modals.changeConsultation.cancelConsultation').toLocaleLowerCase()}</span>{' '}
                                        {encounter?.encounterDetailDTO?.practitionerName} {t('modals.changeConsultation.withDoctor')}{' '}
                                        {t('modals.changeConsultation.appointedOn')}{' '}
                                        {encounter?.encounterDetailDTO?.dateTime && dayjs(encounter?.encounterDetailDTO?.dateTime).format('D MMMM hh:mm')}
                                        .
                                    </p>
                                    <div className={styles.cancelReasonBlock}>
                                        <p className={styles.title}>{t('modals.changeConsultation.cancelReason')}</p>

                                        <Select className="w-100" onChange={(value) => setSelectedReason(value)}>
                                            {(isPatient
                                                ? Object.keys(EncounterChangeReasonEnum).filter(
                                                    (r) =>
                                                        r === EncounterChangeReasonEnum.patient_request ||
                                                        r === EncounterChangeReasonEnum.patient_illness ||
                                                        r === EncounterChangeReasonEnum.technical_problem_on_patient_side ||
                                                        r === EncounterChangeReasonEnum.patient_refuse,
                                                )
                                                : Object.keys(EncounterChangeReasonEnum)
                                            ).map((x) => (
                                                <Option key={x} value={x}>
                                                    {isPatient ? t('enums.patientEncounterChangeReason.' + x) : t('enums.encounterChangeReason.' + x)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <p className={styles.cancelQuestionText}>{t('modals.changeConsultation.cancelConsultationQuestion')}</p>
                                </div>
                            ) : (
                                <>
                                    {currentUser?.role === UserRole.Call_center_manager ? (
                                        <>
                                            <Col md={24} sm={24} xs={24}>
                                                <div className="d-flex">
                                                    <div className={styles.avatar}>
                                                        {encounter?.encounterDetailDTO?.practitionerPhoto ? (
                                                            <img src={encounter?.encounterDetailDTO?.practitionerPhoto} />
                                                        ) : (
                                                            <UserIcon />
                                                        )}
                                                    </div>
                                                    <div style={{ marginLeft: '16px', flexGrow: '1' }}>
                                                        <p className={styles.nameText}>{encounter?.encounterDetailDTO?.practitionerName}</p>
                                                        <div className="d-flex">
                                                            <p>
                                                                {encounter?.encounterDetailDTO?.practitionerRoleCode &&
                                                                    t(
                                                                        `appointment.practitionerRoles.${encounter?.encounterDetailDTO?.practitionerRoleCode}`,
                                                                    )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button type='default' onClick={() => setSelectPractitionerMode(true)}>{t("edit_button")}</Button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <hr />
                                        </>
                                    ) : (
                                        <>
                                            <Col md={24} sm={24} xs={24}>
                                                <div className="d-flex">
                                                    <div>
                                                        <p className={styles.descriptionText}>
                                                            {t('modals.changeConsultation.patientDesc01')}{' '}
                                                            {encounter?.encounterDetailDTO?.practitionerName}{' '}
                                                            {t('modals.changeConsultation.to').toLocaleLowerCase()}{' '}
                                                            {encounter?.encounterDetailDTO?.dateTime &&
                                                                dayjs(encounter?.encounterDetailDTO?.dateTime).format('D MMMM hh:mm')}
                                                            .
                                                        </p>
                                                        <p className={styles.descriptionText} style={{ marginBottom: '24px' }}>
                                                            {t('modals.changeConsultation.patientDesc02')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                    <Col md={12} sm={24} xs={24}>
                                        <CalendarPicker
                                            date={selectedDate}
                                            onMonthChange={(date) => handleMonthChange(date)}
                                            onChange={(date) => {
                                                if (date) {
                                                    handleDateChange(date);
                                                }
                                            }}
                                            renderDay={(day, _value, DayComponentProps) => {
                                                const hasAvailableDoctor = availableSlots.some(
                                                    (x) => day && dayjs(x.dateTime).format('YYYY-MM-DD') === day.format('YYYY-MM-DD'),
                                                );
                                                const inTheFuture = day && day >= dayjs();

                                                return (
                                                    <span
                                                        className={hasAvailableDoctor ? (inTheFuture ? 'filled' : 'skipped') : ''}
                                                        key={day?.toString()}
                                                    >
                                                        {<PickersDay {...DayComponentProps} />}
                                                    </span>
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col md={12} sm={24} xs={24}>
                                        <h3>{selectedDate?.format('D MMMM, dddd')}</h3>
                                        {!!morning?.length && (
                                            <div className="mb-4">
                                                <p className={styles.day}>{t("morning")}</p>
                                                <div className={styles.slotContainer}>
                                                    {morning.map((x, i) => (
                                                        <div
                                                            onClick={() => handleSelectSlot(x)}
                                                            key={`${x.slotId} - ${i}`}
                                                            className={selectedSlot?.slotId === x.slotId ? styles['selected-time'] : styles.time}
                                                        >
                                                            {dayjs(x.dateTime).format('HH:mm')}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {!!day?.length && (
                                            <div className="mb-4">
                                                <p className={styles.day}>{t("afternoon")}</p>
                                                <div className={styles.slotContainer}>
                                                    {day.map((x, i) => (
                                                        <div
                                                            onClick={() => handleSelectSlot(x)}
                                                            key={`${x.slotId} - ${i}`}
                                                            className={selectedSlot?.slotId === x.slotId ? styles['selected-time'] : styles.time}
                                                        >
                                                            {dayjs(x.dateTime).format('HH:mm')}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {!!evening?.length && (
                                            <div className="mb-4">
                                                <p className={styles.day}>{t("evening")}</p>
                                                <div className={styles.slotContainer}>
                                                    {evening.map((x, i) => (
                                                        <div
                                                            onClick={() => handleSelectSlot(x)}
                                                            key={`${x.slotId} - ${i}`}
                                                            className={selectedSlot?.slotId === x.slotId ? styles['selected-time'] : styles.time}
                                                        >
                                                            {dayjs(x.dateTime).format('HH:mm')}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </>
                            )}
                        </Row>
                    </div>
                </LocalizationProvider>
            }
        </Modal>
    );
};
