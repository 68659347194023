import dayjs from 'dayjs';
import Scheduler from 'devextreme-react/scheduler';
import React, { ComponentType, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { ISchedulerSlot } from '../ClinicAdminSchedule';
import { UserDTO } from '@api/userServiceAPI';
import { SlotDTO } from '@api/mainServiceAPI';

interface ISchedulerMemoFcProps {
    schedulerRef: RefObject<Scheduler>;
    currentDate: Date;
    currentView: 'day' | 'week' | 'month';
    events: SlotDTO[];
    selectedEmployee: UserDTO;
    handleReadyChanged: () => void;
    renderAppointment: (data: { appointmentData: ISchedulerSlot }) => JSX.Element;
    onCellClick: (e: any) => void;
    onAppointmentClick: (data: any) => void;
    renderAppointmentCollectorComponent: ComponentType<any> | undefined;
    renderAppointmentCollector: (data: { appointmentData: ISchedulerSlot }) => JSX.Element;
}

const SchedulerMemoFc = ({
    schedulerRef,
    currentDate,
    currentView,
    events,
    selectedEmployee,
    handleReadyChanged,
    renderAppointment,
    onCellClick,
    onAppointmentClick,
    renderAppointmentCollector,
    renderAppointmentCollectorComponent,
}: ISchedulerMemoFcProps) => {
    const { t } = useTranslation();

    return (
        <Scheduler
            ref={schedulerRef}
            defaultCurrentDate={currentDate}
            currentView={currentView}
            firstDayOfWeek={1}
            showAllDayPanel={false}
            useDropDownViewSwitcher={false}
            editing={false}
            dataSource={events?.map((event) => ({
                ...event,
                name: `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`,
                role: t(`adminMainPage.roles.${selectedEmployee.userProfileDTOList?.[0].profileType}`),
                id: event.id,
                startDate: dayjs(event.startDateTime).toDate(),
                endDate: dayjs(event.endDateTime).toDate(),
                patientsNumber: event.patientsNumber,
                duration: dayjs(event.endDateTime).diff(dayjs(event.startDateTime), 'minute'),
            }))}
            onContentReady={handleReadyChanged}
            appointmentRender={renderAppointment}
            onCellClick={onCellClick}
            onAppointmentClick={onAppointmentClick}
            onAppointmentDblClick={onCellClick}
            appointmentTooltipRender={renderAppointmentCollector}
            appointmentCollectorComponent={renderAppointmentCollectorComponent}
            onAppointmentContextMenu={onAppointmentClick}
            onAppointmentFormOpening={onAppointmentClick}
        />
    );
};

const isTheSame = (prevProps: ISchedulerMemoFcProps, nextProps: ISchedulerMemoFcProps): boolean => {
    return prevProps.events === nextProps.events && prevProps.currentView === nextProps.currentView;
};

export const SchedulerMemo = React.memo(SchedulerMemoFc, isTheSame);
