import { CalendarInputIcon } from '@icons';
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IDiagnosticBinocularResult, IDiagnosticVisualAcuityResult } from 'src/components/diagnostics';
import styles from './StatusOculorumStep.module.scss';
import { BinocularDiagnosticOptions, BinocularTypeOptions, DataSourceOptions, VisualAcuityDiagnosticOptions } from './dictionary';
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

export interface IStatusOculorumForm {
    diagnosticVisualAcuityMode?: 'auto' | 'manually' | 'byPatient';
    diagnosticVisualAcuity?: IDiagnosticVisualAcuityResult;

    diagnosticBinocularMode?: 'auto' | 'manually' | 'byPatient' | 'noData';
    diagnosticBinocular?: IDiagnosticBinocularResult;

    od_sph?: number;
    od_cyl?: number;
    od_ax?: number;
    os_sph?: number;
    os_cyl?: number;
    os_ax?: number;

    ownGlassesData?: string;
    ownGlassesDataSource?: string;
    ownGlassesDataDate?: dayjs.Dayjs | string;

    angleSinoptoforData?: string;
    angleSinoptoforDataSource?: string;
    angleSinoptoforDataDate?: dayjs.Dayjs | string;

    otherValuesData?: string;
    otherValuesDataSource?: string;
    otherValuesDataDate?: dayjs.Dayjs | string;

    synoptophore_with_glasses?: number;
    synoptophore_without_glasses?: number;

    girshberg_with_glasses?: number;
    girshberg_without_glasses?: number;

    eyeballsMovement?: string;
    convergence?: string;
    additionally?: string;
    comment?: string;
    [key: string]: any;
}

interface IStatusOculorumStepProps {
    initialValues?: IStatusOculorumForm;
    disabled?: boolean;
    justDiagnostic?: boolean;

    onSubmit: (formValue: IStatusOculorumForm) => void;
    onFormChange: (formValue: IStatusOculorumForm) => void;
}

export const StatusOculorumStep = ({ initialValues, disabled, justDiagnostic = false, onFormChange, onSubmit }: IStatusOculorumStepProps) => {
    const { t } = useTranslation();
    const [form] = useForm<IStatusOculorumForm>();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    useEffect(() => {
        if (diagnosticVisualAcuityModeValue && diagnosticVisualAcuityModeValue !== 'auto') {
            const currentTestValue = form.getFieldValue('diagnosticVisualAcuity');
            form.setFieldsValue({
                diagnosticVisualAcuity: {
                    ...currentTestValue,
                    vis_od_glasses_off: isNaN(Number(form.getFieldValue(['diagnosticVisualAcuity', 'vis_od_glasses_off'])))
                        ? undefined
                        : form.getFieldValue(['diagnosticVisualAcuity', 'vis_od_glasses_off']),
                    vis_os_glasses_off: isNaN(Number(form.getFieldValue(['diagnosticVisualAcuity', 'vis_os_glasses_off'])))
                        ? undefined
                        : form.getFieldValue(['diagnosticVisualAcuity', 'vis_os_glasses_off']),
                    vis_od_glasses_on: isNaN(Number(form.getFieldValue(['diagnosticVisualAcuity', 'vis_od_glasses_on'])))
                        ? undefined
                        : form.getFieldValue(['diagnosticVisualAcuity', 'vis_od_glasses_on']),
                    vis_os_glasses_on: isNaN(Number(form.getFieldValue(['diagnosticVisualAcuity', 'vis_os_glasses_on'])))
                        ? undefined
                        : form.getFieldValue(['diagnosticVisualAcuity', 'vis_os_glasses_on']),
                },
            });
        }
    }, [form.getFieldValue('diagnosticVisualAcuityMode')]);

    // Ant doesn't rerender the whole form  if some field changes (even with shouldUpdate)
    // https://nanxiaobei.medium.com/watch-a-more-elegant-way-to-monitor-antd-form-field-changes-7c9b12457d67
    const diagnosticVisualAcuityModeValue = useWatch('diagnosticVisualAcuityMode', form);
    const diagnosticBinocularModeValue = useWatch('diagnosticBinocularMode', form);
    const ownGlassesDataValue = useWatch('ownGlassesData', form);
    const angleSinoptoforDataValue = useWatch('angleSinoptoforData', form);
    const otherValuesDataValue = useWatch('otherValuesData', form);
    const vis_od_glasses_off = useWatch(['diagnosticVisualAcuity', 'vis_od_glasses_off'], form);
    const vis_os_glasses_off = useWatch(['diagnosticVisualAcuity', 'vis_os_glasses_off'], form);
    const vis_od_glasses_on = useWatch(['diagnosticVisualAcuity', 'vis_od_glasses_on'], form);
    const vis_os_glasses_on = useWatch(['diagnosticVisualAcuity', 'vis_os_glasses_on'], form);

    const handleFinish = (values: IStatusOculorumForm) => {
        const fieldsToUpdate = [
            'od_sph',
            'od_cyl',
            'od_ax',
            'os_sph',
            'os_cyl',
            'os_ax',
            'synoptophore_with_glasses',
            'synoptophore_without_glasses',
            'girshberg_with_glasses',
            'girshberg_without_glasses',
        ];
        fieldsToUpdate.forEach((field) => {
            if (!!values[field] && !values[field].toString().includes('+') && values[field] > 0) {
                values[field] = `+${values[field]}`;
            }
        });

        onSubmit(values);
    };

    return (
        <div className={styles.wrapper}>
            {!justDiagnostic && <h2 className={styles.title}>{t('consultationSession.statusOculorumStep.title')}</h2>}

            <Form form={form} autoComplete="off" onFinish={handleFinish} onValuesChange={(_, all) => onFormChange(all)} disabled={disabled}>
                <div className={styles.diagnosticResults}>
                    <p className={styles.groupLabel}>{t("status_oculorum_step.vision")}</p>

                    <div className={styles.formGroup}>
                        <Form.Item name="diagnosticVisualAcuityMode" className={classNames(styles.selector, 'mb-2')}>
                            <Select>
                                {VisualAcuityDiagnosticOptions.map((x) => (
                                    <Option key={x} value={x}>
                                        {t('consultationSession.statusOculorumStep.visualAcuityDiagnosticOptions.' + x)}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {diagnosticVisualAcuityModeValue === 'byPatient' && (
                            <div className={styles.formGrid22}>
                                <div className="datepicker" style={{ width: '220px' }}>
                                    <Form.Item name="ownGlassesDataDate" rules={[{ required: true, message: t("required_field") }]}>
                                        <DatePicker
                                            placeholder={t("placeholder_select_date")}
                                            className="w-100 topIndex"
                                            suffixIcon={<CalendarInputIcon />}
                                            popupClassName="topIndex"
                                        />
                                    </Form.Item>
                                </div>

                                <Form.Item name="ownGlassesDataSource" rules={[{ required: true, message: t("required_field") }]}>
                                    <Input placeholder={t("status_oculorum_step.source_required")} />
                                </Form.Item>
                            </div>
                        )}
                    </div>

                    <Form.Item
                        name={['diagnosticVisualAcuity', 'vis_od_glasses_off']}
                        label={t("status_oculorum_step.vis_od_without_glasses")}
                        rules={[{ required: true, message: t("required_field") }]}
                        shouldUpdate
                    >
                        {diagnosticVisualAcuityModeValue === 'auto' && isNaN(Number(vis_od_glasses_off)) ? (
                            <>
                                <Input
                                    disabled={true}
                                    value={
                                        !vis_od_glasses_off
                                            ? undefined
                                            : t('diagnosticSession.visualAcuityValues.' + vis_od_glasses_off, { defaultValue: vis_od_glasses_off })
                                    }
                                />
                            </>
                        ) : (
                            <Input disabled={diagnosticVisualAcuityModeValue === 'auto' || disabled} />
                        )}
                    </Form.Item>
                    <Form.Item
                        name={['diagnosticVisualAcuity', 'vis_os_glasses_off']}
                        label={t("status_oculorum_step.vis_os_without_glasses")}
                        rules={[{ required: true, message: t("required_field") }]}
                        shouldUpdate
                    >
                        {diagnosticVisualAcuityModeValue === 'auto' && isNaN(Number(vis_os_glasses_off)) ? (
                            <>
                                <Input
                                    disabled={true}
                                    value={
                                        !vis_os_glasses_off
                                            ? undefined
                                            : t('diagnosticSession.visualAcuityValues.' + vis_os_glasses_off, { defaultValue: vis_os_glasses_off })
                                    }
                                />
                            </>
                        ) : (
                            <Input disabled={diagnosticVisualAcuityModeValue === 'auto' || disabled} />
                        )}
                    </Form.Item>
                    <Form.Item
                        name={['diagnosticVisualAcuity', 'vis_od_glasses_on']}
                        label={t("status_oculorum_step.vis_od_with_glasses")}
                        rules={[
                            {
                                required: initialValues?.diagnosticVisualAcuity?.withGlasses,
                                message: t("required_field"),
                            },
                        ]}
                    >
                        {diagnosticVisualAcuityModeValue === 'auto' && isNaN(Number(vis_od_glasses_on)) ? (
                            <>
                                <Input
                                    disabled={true}
                                    value={
                                        !vis_od_glasses_on
                                            ? undefined
                                            : t('diagnosticSession.visualAcuityValues.' + vis_od_glasses_on, { defaultValue: vis_od_glasses_on })
                                    }
                                />
                            </>
                        ) : (
                            <Input disabled={diagnosticVisualAcuityModeValue === 'auto' || disabled} />
                        )}
                    </Form.Item>
                    <Form.Item
                        name={['diagnosticVisualAcuity', 'vis_os_glasses_on']}
                        label={t("status_oculorum_step.vis_os_with_glasses")}
                        rules={[
                            {
                                required: initialValues?.diagnosticVisualAcuity?.withGlasses,
                                message: t("required_field"),
                            },
                        ]}
                    >
                        {diagnosticVisualAcuityModeValue === 'auto' && isNaN(Number(vis_os_glasses_on)) ? (
                            <>
                                <Input
                                    disabled={true}
                                    value={
                                        !vis_os_glasses_on
                                            ? undefined
                                            : t('diagnosticSession.visualAcuityValues.' + vis_os_glasses_on, { defaultValue: vis_os_glasses_on })
                                    }
                                />
                            </>
                        ) : (
                            <Input disabled={diagnosticVisualAcuityModeValue === 'auto' || disabled} />
                        )}
                    </Form.Item>
                </div>

                <p className={styles.groupLabel}>{t("status_oculorum_step.my_glasses")}</p>
                <div className={styles.glassesGrid}>
                    <p className={styles.groupLabel}>OD</p>
                    <span className="ant-form-text">sph</span>
                    <Form.Item
                        name="od_sph"
                        className="mb-0"
                        rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                    >
                        <InputNumber step={0.1} />
                    </Form.Item>
                    <span className="ant-form-text">cyl</span>
                    <Form.Item
                        name="od_cyl"
                        className="mb-0"
                        rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                    >
                        <InputNumber step={0.1} />
                    </Form.Item>
                    <span className="ant-form-text">ax</span>
                    <Form.Item
                        name="od_ax"
                        className="mb-0"
                        rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                    >
                        <InputNumber step={0.1} />
                    </Form.Item>

                    <p className={styles.groupLabel}>OS</p>
                    <span className="ant-form-text">sph</span>
                    <Form.Item
                        name="os_sph"
                        className="mb-0"
                        rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                    >
                        <InputNumber step={0.1} />
                    </Form.Item>
                    <span className="ant-form-text">cyl</span>
                    <Form.Item
                        name="os_cyl"
                        className="mb-0"
                        rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                    >
                        <InputNumber step={0.1} />
                    </Form.Item>
                    <span className="ant-form-text">ax</span>
                    <Form.Item
                        name="os_ax"
                        className="mb-0"
                        rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                    >
                        <InputNumber step={0.1} />
                    </Form.Item>
                </div>

                <div className="mt-3 mb-5">
                    <Form.Item name="ownGlassesData" className="mb-2" rules={[{ required: true, message: t("required_field") }]}>
                        <Select placeholder={t("placeholder_select_datasource")}>
                            {DataSourceOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {t('consultationSession.statusOculorumStep.dataSourceOptions.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {ownGlassesDataValue === 'byPatient' && (
                        <div className={styles.formGrid22}>
                            <div className="datepicker" style={{ width: '220px' }}>
                                <Form.Item name="ownGlassesDataDate" rules={[{ required: true, message: t("required_field") }]}>
                                    <DatePicker
                                        placeholder={t("placeholder_select_date")}
                                        className="w-100 topIndex"
                                        suffixIcon={<CalendarInputIcon />}
                                        popupClassName="topIndex"
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item name="ownGlassesDataSource" rules={[{ required: true, message: t("required_field") }]}>
                                <Input placeholder={t("status_oculorum_step.source_required")} />
                            </Form.Item>
                        </div>
                    )}
                </div>

                <div className={styles.diagnosticResults}>
                    <p className={styles.groupLabel}>{t("status_oculorum_step.binocular_functions")}</p>

                    <p className={styles.groupSubLabel}>{t("status_oculorum_step.by_girsberg")}</p>
                    <div className={classNames(styles.formGrid22, 'mb-4')}>
                        <label>{t("status_oculorum_step.without_glasses")}</label>
                        <Form.Item className={styles.formGroup}>
                            <Form.Item name="girshberg_without_glasses" noStyle>
                                <InputNumber
                                    step={0.1}
                                    onChange={(value) => {
                                        if (value && +value > 0) {
                                            return Math.abs(+value);
                                        }
                                        return value;
                                    }}
                                />
                            </Form.Item>
                            <span className="ant-form-text">{t("status_oculorum_step.deg")}</span>
                        </Form.Item>

                        <label>{t("status_oculorum_step.with_glasses")}</label>
                        <Form.Item className={styles.formGroup}>
                            <Form.Item name="girshberg_with_glasses" noStyle>
                                <InputNumber
                                    step={0.1}
                                    onChange={(value) => {
                                        if (value && +value > 0) {
                                            return Math.abs(+value);
                                        }
                                        return value;
                                    }}
                                />
                            </Form.Item>
                            <span className="ant-form-text">{t("status_oculorum_step.deg")}</span>
                        </Form.Item>
                    </div>

                    <div className={styles.formGroup}>
                        <Form.Item name="diagnosticBinocularMode" className={classNames(styles.selector, 'mb-2')}>
                            <Select>
                                {BinocularDiagnosticOptions.map((x) => (
                                    <Option key={x} value={x}>
                                        {t('consultationSession.statusOculorumStep.binocularDiagnosticOptions.' + x)}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {diagnosticBinocularModeValue === 'byPatient' && (
                            <div className={styles.formGrid22}>
                                <div className="datepicker" style={{ width: '220px' }}>
                                    <Form.Item name="ownGlassesDataDate" rules={[{ required: true, message: t("required_field") }]}>
                                        <DatePicker
                                            placeholder={t("placeholder_select_date")}
                                            className="w-100 topIndex"
                                            suffixIcon={<CalendarInputIcon />}
                                            popupClassName="topIndex"
                                        />
                                    </Form.Item>
                                </div>

                                <Form.Item name="ownGlassesDataSource" rules={[{ required: true, message: t("required_field") }]}>
                                    <Input placeholder={t("status_oculorum_step.source_required")} />
                                </Form.Item>
                            </div>
                        )}
                    </div>

                    <Form.Item
                        name={['diagnosticBinocular', 'nature_of_vision_33_sm_without_glasses']}
                        label={t("status_oculorum_step.vision_33cm_without_glasses")}
                        rules={[{ required: diagnosticBinocularModeValue !== 'noData', message: t("required_field") }]}
                    >
                        <Select disabled={diagnosticBinocularModeValue === 'auto' ||  diagnosticBinocularModeValue === 'noData' || disabled} allowClear>
                            {BinocularTypeOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {t('binocularDiagnostic.binocularTypes.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={['diagnosticBinocular', 'nature_of_vision_33_sm_with_glasses']}
                        label={t("status_oculorum_step.vision_33cm_with_glasses")}
                        rules={[
                            {
                                required: initialValues?.diagnosticVisualAcuity?.withGlasses && diagnosticBinocularModeValue !== 'noData',
                                message: t("required_field"),
                            },
                        ]}
                    >
                        <Select disabled={diagnosticBinocularModeValue === 'auto' ||  diagnosticBinocularModeValue === 'noData' || disabled} allowClear>
                            {BinocularTypeOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {t('binocularDiagnostic.binocularTypes.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={['diagnosticBinocular', 'nature_of_vision_1_m_without_glasses']}
                        label={t("status_oculorum_step.vision_1m_without_glasses")}
                        rules={[{ required: diagnosticBinocularModeValue !== 'noData', message: t("required_field") }]}
                    >
                        <Select disabled={diagnosticBinocularModeValue === 'auto' || diagnosticBinocularModeValue === 'noData' || disabled} allowClear>
                            {BinocularTypeOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {t('binocularDiagnostic.binocularTypes.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={['diagnosticBinocular', 'nature_of_vision_1_m_with_glasses']}
                        label={t("status_oculorum_step.vision_1m_with_glasses")}
                        rules={[
                            {
                                required: initialValues?.diagnosticVisualAcuity?.withGlasses && diagnosticBinocularModeValue !== 'noData',
                                message: t("required_field"),
                            },
                        ]}
                    >
                        <Select disabled={diagnosticBinocularModeValue === 'auto' ||  diagnosticBinocularModeValue === 'noData' || disabled} allowClear>
                            {BinocularTypeOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {t('binocularDiagnostic.binocularTypes.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <p className={styles.groupLabel}>{t("status_oculorum_step.sinoptophor")}</p>
                <div className={styles.formGrid22}>
                    <label>{t("status_oculorum_step.without_glasses")}</label>
                    <Form.Item className={styles.formGroup}>
                        <Form.Item
                            name="synoptophore_without_glasses"
                            className="mb-0"
                            rules={[{ required: !!angleSinoptoforDataValue && angleSinoptoforDataValue !== 'noData', message: t("required_field") }]}
                        >
                            <InputNumber
                                step={0.1}
                                onChange={(value) => {
                                    if (value && +value > 0) {
                                        return Math.abs(+value);
                                    }
                                    return value;
                                }}
                            />
                        </Form.Item>
                        <span className="ant-form-text">{t("status_oculorum_step.deg")}</span>
                    </Form.Item>

                    <label>{t("status_oculorum_step.without_glasses")}</label>
                    <Form.Item className={styles.formGroup}>
                        <Form.Item name="synoptophore_with_glasses" className="mb-0">
                            <InputNumber
                                step={0.1}
                                onChange={(value) => {
                                    if (value && +value > 0) {
                                        return Math.abs(+value);
                                    }
                                    return value;
                                }}
                            />
                        </Form.Item>
                        <span className="ant-form-text">{t("status_oculorum_step.deg")}</span>
                    </Form.Item>
                </div>

                <div className="mt-3 mb-5">
                    <Form.Item name="angleSinoptoforData" className="mb-2" rules={[{ required: true, message: t("required_field") }]}>
                        <Select placeholder={t("placeholder_select_datasource")}>
                            {DataSourceOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {t('consultationSession.statusOculorumStep.dataSourceOptions.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {angleSinoptoforDataValue === 'byPatient' && (
                        <div className={styles.formGrid22}>
                            <div className="datepicker" style={{ width: '220px' }}>
                                <Form.Item name="angleSinoptoforDataDate" rules={[{ required: true, message: t("required_field") }]}>
                                    <DatePicker
                                        placeholder={t("placeholder_select_date")}
                                        className="w-100 topIndex"
                                        suffixIcon={<CalendarInputIcon />}
                                        popupClassName="topIndex"
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item name="angleSinoptoforDataSource" rules={[{ required: true, message: t("required_field") }]}>
                                <Input placeholder={t("status_oculorum_step.source_required")} />
                            </Form.Item>
                        </div>
                    )}
                </div>

                <p className={styles.groupLabel}>{t("status_oculorum_step.other_values")}</p>
                <div className={styles.formGrid22}>
                    <label>{t("status_oculorum_step.eyes_action")}</label>
                    <Form.Item
                        name="eyeballsMovement"
                        rules={[{ required: !!otherValuesDataValue && otherValuesDataValue !== 'noData', message: t("required_field") }]}
                    >
                        <Input />
                    </Form.Item>

                    <label>{t("status_oculorum_step.convergence")}</label>
                    <Form.Item
                        name="convergence"
                        rules={[{ required: !!otherValuesDataValue && otherValuesDataValue !== 'noData', message: t("required_field") }]}
                    >
                        <Input />
                    </Form.Item>

                    <label>{t("status_oculorum_step.additionally")}</label>
                    <Form.Item
                        name="additionally"
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>

                    <label>{t("status_oculorum_step.comment")}</label>
                    <Form.Item name="comment" className={styles.formGroup} rules={[{required: false}]}>
                        <TextArea placeholder={t("placeholder_comment")} />
                    </Form.Item>
                </div>

                <div className="mt-3 mb-4">
                    <Form.Item name="otherValuesData" className="mb-2" rules={[{ required: true, message: t("required_field") }]}>
                        <Select placeholder={t("placeholder_select_datasource")}>
                            {DataSourceOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {t('consultationSession.statusOculorumStep.dataSourceOptions.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {otherValuesDataValue === 'byPatient' && (
                        <div className={styles.formGrid22}>
                            <div className="datepicker" style={{ width: '220px' }}>
                                <Form.Item name="otherValuesDataDate" rules={[{ required: true, message: t("required_field") }]}>
                                    <DatePicker
                                        placeholder={t("placeholder_select_date")}
                                        className="w-100 topIndex"
                                        suffixIcon={<CalendarInputIcon />}
                                        popupClassName="topIndex"
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item name="otherValuesDataSource" rules={[{ required: true, message: t("required_field") }]}>
                                <Input placeholder={t("status_oculorum_step.source_required")} />
                            </Form.Item>
                        </div>
                    )}
                </div>

                {!disabled && !justDiagnostic && (
                    <Button className="w-100 mt-3" type="primary" htmlType="submit">
                        {t("next_button")}
                    </Button>
                )}
            </Form>
        </div>
    );
};
