import { CustomPhoneInput } from '@components';
import { CalendarInputIcon, EyeClosed, EyeOpen } from '@icons';
import {
    getAuthUser,
    IUserRegisterDTO,
    registerEsia, registerWithoutPhoneConfirmation,
    remoteValidationError,
    selectAccountFormData,
    selectEmail,
    selectEmailTokenAfter,
    selectPhoneNumber, selectRegisteredSuccessfully,
    selectRemoteValidationMessages,
    selectSessionIdSecond,
    sendSMS,
    storeFormData,
} from '@sliceUser';
import { PHONE_VALIDATION } from '@utils/constants';
import { customRequired } from '@utils/utils';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './AccountPage.module.scss';
import { getLogo, getStyles } from 'src/store/organization';
import { UserDirectDocumentDTOType } from '@api/documentServiceAPI';
import { Logo } from 'src/components/Logo/Logo';
import useOpenFile from 'src/hooks/open-file.hook';
import { register } from '@api/userServiceAPI';

interface IAccountForm {
    lastName: string;
    firstName: string;
    middleName: string;
    mobile: string;
    birthDate: dayjs.Dayjs;
    password: string;
    confirmPassword: string;
    noMiddleName: boolean;
    acceptUserAgreement: boolean;
    acceptPersonalDataProcessing: boolean;
    acceptPrivacyPolicy: boolean;
}

export const AccountPage = ({ edit }: { edit?: boolean }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [noMiddleNameFlag, setNoMiddleNameFlag] = useState(false);
    const [showSmallLabel, setShowSmallLabel] = useState(true);
    const [showAgreementError, setShowAgreementError] = useState(false);
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const emailTokenAfter = useSelector(selectEmailTokenAfter);
    const sessionIdSecond = useSelector(selectSessionIdSecond);
    const accountFormData = useSelector(selectAccountFormData);
    const registeredSuccessfully = useSelector(selectRegisteredSuccessfully);
    const accountPhoneNumber = useSelector(selectPhoneNumber);
    const remoteValidationMessages = useSelector(selectRemoteValidationMessages);
    const email = useSelector(selectEmail);
    const openFile = useOpenFile();

    const { validateForm, isSubmitDisabled } = useFormValidation(form);

    useEffect(() => {
        dispatch(getLogo());
        dispatch(getStyles());
    }, []);

    useEffect(() => {
        if (!email && !accountFormData?.isEsia) {
            navigate('/');
        }
    }, [email]);

    useEffect(() => {
        if (accountFormData) {
            const noMiddleName = !!accountFormData?.noMiddleName || !!(accountFormData.isEsia && !accountFormData.middleName);
            setNoMiddleNameFlag(noMiddleName);
        }
    }, [accountFormData]);

    useEffect(() => {
        if (remoteValidationMessages.mobile) {
            const value = form.getFieldValue('mobile');
            form.setFields([{ name: 'mobile', value, errors: [t(remoteValidationMessages.mobile)] }]);
            mobileChangeHandler();
        }
    }, [remoteValidationMessages]);

    useEffect(() => {
        validate();
    }, [noMiddleNameFlag, showSmallLabel]);

    useEffect(() => {
        if (sessionIdSecond) {
            navigate('/confirm-mobile');
        }
    }, [sessionIdSecond]);

    useEffect(() => {
        if (registeredSuccessfully) {
            navigate('/account-success');
        }
    }, [registeredSuccessfully]);

    const cancel = (evt: MouseEvent) => {
        evt.preventDefault();
        navigate('/');
    };

    const noMiddleNameHandler = (evt: CheckboxChangeEvent) => {
        if (form && evt.target.checked && form.getFieldValue('middleName')) {
            form.setFields([{ name: 'middleName', value: null, errors: [] }]);
        }
        setNoMiddleNameFlag(evt.target.checked);
        form?.validateFields(['middleName']);
    };

    const mobileChangeHandler = () => {
        if (form) {
            setTimeout(() => {
                setShowSmallLabel(form.getFieldError('mobile').length === 0);
                dispatch(remoteValidationError({ field: 'mobile' }));
            });
        }
    };

    const convertFormData = (formData: IAccountForm): IUserRegisterDTO => {
        const { firstName, middleName, lastName, birthDate, password } = formData;
        return noMiddleNameFlag
            ? {
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                  birthDate: birthDate.format('YYYY-MM-DD'),
                  password,
                  noMiddleName: noMiddleNameFlag,
              }
            : {
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                  birthDate: birthDate.format('YYYY-MM-DD'),
                  password,
                  noMiddleName: noMiddleNameFlag,
                  middleName: middleName.trim(),
              };
    };

    const saveAccount = (formValue: IAccountForm) => {
        if (!form) {
            return;
        }
        form.validateFields();
        agreementChangeHandler();
        mobileChangeHandler();
        setTimeout(() => {
            if (!form.getFieldsError().find(({ errors }: { errors: string[] }) => errors.length > 0)) {
                dispatch(storeFormData(convertFormData(formValue)));
                if(!accountFormData?.isEsia) {
                    dispatch(registerWithoutPhoneConfirmation({ accountFormData: {...convertFormData(formValue), phone: formValue.mobile}, emailTokenAfter: emailTokenAfter! }));
                } else {
                    dispatch(registerEsia({
                        user: accountFormData,
                        email: email ?? '',
                        phone: formValue.mobile
                    }));
                }
            }
        });
    };

    const agreementChangeHandler = (fld?: string) => {
        if (!form) {
            return;
        }
        const agreementFields = ['acceptUserAgreement', 'acceptPersonalDataProcessing', 'acceptPrivacyPolicy'];
        if (fld) {
            form.validateFields([fld]);
        }
        setTimeout(() => {
            setShowAgreementError(!!form.getFieldsError(agreementFields).find(({ errors }: { errors: string[] }) => errors.length > 0));
        });
    };

    const validateConfirmPwd = () => {
        if (!form) {
            return;
        }
        if (form.getFieldValue('confirmPassword')) {
            form.validateFields(['confirmPassword']);
        }
    };

    const validate = () => {
        const fieldsToCheck = ['lastName', 'firstName', 'mobile', 'password', 'birthDate', 'confirmPassword'];
        if (!noMiddleNameFlag) {
            fieldsToCheck.push('middleName');
        }
        validateForm(fieldsToCheck, () => {
            const additionalFieldsToCheck = ['acceptUserAgreement', 'acceptPersonalDataProcessing', 'acceptPrivacyPolicy'];
            return Object.values(form.getFieldsValue(additionalFieldsToCheck)).includes(false);
        });
    };

    return (
        <div className={styles.wrapper}>
            <Card className={styles.form}>
                <Logo marginBottom />
                <h4>{edit ? t('Account.edit') : t('Account.create')}</h4>
                <div
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                        __html: t('Account.createDescription'),
                    }}
                />
                <Form
                    form={form}
                    onFinish={saveAccount}
                    name="account"
                    layout="vertical"
                    wrapperCol={{ span: 24 }}
                    initialValues={{
                        lastName: accountFormData?.lastName || '',
                        firstName: accountFormData?.firstName || '',
                        middleName: accountFormData?.middleName || '',
                        mobile: accountPhoneNumber || '',
                        password: accountFormData?.password || '',
                        birthDate: accountFormData?.birthDate ? dayjs(accountFormData.birthDate, 'YYYY-MM-DD') : null,
                        confirmPassword: accountFormData?.password || '',
                        acceptUserAgreement: false,
                        acceptPersonalDataProcessing: false,
                        acceptPrivacyPolicy: false,
                    }}
                    autoComplete="off"
                    requiredMark={false}
                    onChange={validate}
                >
                    <Row gutter={24} className={styles.mainBlock}>
                        <Col md={8} sm={24} xs={24}>
                            <Form.Item
                                name="lastName"
                                label={t('Account.labels.lastName')}
                                rules={[customRequired(t('Account.validation.lastNameIsRequired'))]}
                            >
                                <Input autoFocus disabled={accountFormData?.isEsia} />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                            <Form.Item
                                name="firstName"
                                label={t('Account.labels.firstName')}
                                rules={[customRequired(t('Account.validation.firstNameIsRequired'))]}
                            >
                                <Input disabled={accountFormData?.isEsia} />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                            <Form.Item
                                name="middleName"
                                className={styles.labelW100}
                                label={
                                    <Row className="w-100" justify="space-between">
                                        <Col>{t('Account.labels.middleName')}</Col>
                                        <Col>
                                            <Checkbox
                                                className={classNames(styles.smallCheckBox, styles.checkboxItem)}
                                                onChange={noMiddleNameHandler}
                                                checked={noMiddleNameFlag}
                                                disabled={accountFormData?.isEsia}
                                            >
                                                {t('Account.labels.noMiddleName')}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                }
                                rules={[customRequired(t('Account.validation.middleNameIsRequired'), !noMiddleNameFlag)]}
                            >
                                <Input disabled={noMiddleNameFlag || accountFormData?.isEsia} />
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item
                                name="mobile"
                                label={t('Account.labels.mobile')}
                                rules={[
                                    { required: true, message: t('Account.validation.mobileIsRequired') },
                                    {
                                        pattern: new RegExp(PHONE_VALIDATION),
                                        message: t('Account.validation.mobileFormat'),
                                    },
                                ]}
                            >
                                <CustomPhoneInput onChange={mobileChangeHandler} disabled={accountFormData?.isEsia} />
                            </Form.Item>
                            {showSmallLabel && !accountFormData?.isEsia && (
                                <label className={styles.lightLabel}>
                                    <small>{t('Account.smsConfirmation')}</small>
                                </label>
                            )}
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item
                                name="birthDate"
                                label={t('Account.labels.birthDate')}
                                rules={[
                                    { required: true, message: t('Account.validation.birthDateIsRequired') },
                                    {
                                        validator(_, value: dayjs.Dayjs) {
                                            if (!value || value > dayjs().subtract(18, 'year')) {
                                                return Promise.reject(t('Account.validation.under18'));
                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    },
                                ]}
                            >
                                <DatePicker
                                    autoComplete="off"
                                    inputReadOnly
                                    placeholder=""
                                    className="w-100"
                                    suffixIcon={<CalendarInputIcon />}
                                    disabledDate={(e) => e > dayjs()}
                                    disabled={accountFormData?.isEsia}
                                />
                            </Form.Item>
                        </Col>
                        {
                            !accountFormData?.isEsia ?
                            <>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="password"
                                        label={t('Account.labels.password')}
                                        rules={[{ required: true, message: t('Account.validation.passwordIsRequired') }]}
                                    >
                                        <Input.Password
                                            onChange={validateConfirmPwd}
                                            iconRender={(isOpen) =>
                                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="confirmPassword"
                                        label={t('Account.labels.confirmPassword')}
                                        rules={[
                                            { required: true, message: t('Account.validation.confirmPasswordIsRequired') },
                                            ({ getFieldValue }: { getFieldValue: (fld: string) => string | undefined }) => ({
                                                validator(_, value: string) {   
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t('Account.validation.confirmPasswordDoesNotMatch')));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            iconRender={(isOpen) =>
                                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                            : null
                        }
                    </Row>
                    <Form.Item
                        className={styles.checkboxItem}
                        name="acceptUserAgreement"
                        valuePropName="checked"
                        rules={[
                            () => ({
                                validator(_, value: string) {
                                    if (value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(''));
                                },
                            }),
                        ]}
                    >
                        <Checkbox onChange={() => agreementChangeHandler('acceptUserAgreement')}>
                            {t('Account.labels.iAccept')}{' '}
                            <Button 
                                type='link' 
                                className={styles.documentLink} 
                                onClick={() => openFile(UserDirectDocumentDTOType.USER_AGREEMENT_PATIENTS)}
                            >
                                {t('Account.labels.userAgreement')}
                            </Button>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        className={styles.checkboxItem}
                        name="acceptPersonalDataProcessing"
                        valuePropName="checked"
                        rules={[
                            () => ({
                                validator(_, value: string) {
                                    if (value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(''));
                                },
                            }),
                        ]}
                    >
                        <Checkbox onChange={() => agreementChangeHandler('acceptPersonalDataProcessing')}>
                            {t('Account.labels.iAccept')}{' '}
                            <Button 
                                type='link' 
                                className={styles.documentLink} 
                                onClick={() => openFile(UserDirectDocumentDTOType.PERSONAL_DATA_CONSENT)}
                            >
                                {t('Account.labels.personalDataProcessing')}

                            </Button>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        className={styles.checkboxItem}
                        name="acceptPrivacyPolicy"
                        valuePropName="checked"
                        rules={[
                            () => ({
                                validator(_, value: string) {
                                    if (value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(''));
                                },
                            }),
                        ]}
                    >
                        <Checkbox onChange={() => agreementChangeHandler('acceptPrivacyPolicy')}>
                            {t('Account.labels.iAccept')}{' '}
                            <Button 
                                type='link' 
                                className={styles.documentLink} 
                                onClick={() => openFile(UserDirectDocumentDTOType.PRIVACY_POLICY)}
                            >
                                {t('Account.labels.privacyPolicy')}

                            </Button>
                        </Checkbox>
                    </Form.Item>
                    {showAgreementError && (
                        <div className={classNames('ant-form-item-explain-error', styles.error)}>{t('Account.validation.acceptAll')}</div>
                    )}
                    <Row justify="space-between" align="middle" className={styles.bottomSection}>
                        <Button type="default" htmlType="button" onClick={cancel}>
                            {t('Account.labels.cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {t('Account.labels.create')}
                        </Button>
                    </Row>
                </Form>
            </Card>
        </div>
    );
};
